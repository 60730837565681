import { ErrorMessageWrapper } from "@/common/components/ErrorMessageWrapper";
import ListFilters from "@/common/components/ListFilters";
import ListHeader from "@/common/components/ListHeader";
import Table from "@/common/components/Table";
import { DatePickerWithRange } from "@/common/components/ui/dateRangePicker";
import useDebounce from "@/common/hooks/useDebounce";
import { useFilterParams } from "@/common/hooks/useFilterParams";
import { Mapper } from "@/common/services/mapper";
import { Option } from "@/common/types/option";
import { dateToISOStingTransformer } from "@/common/utils/dateTransformer";
import { fromInternalBaseOptions } from "@/features/billingBills/constants/fromInternalBaseOptions";
import { useEstablishmentCompanies } from "@/features/companyBranches/hooks/useEstablishmentCompanies";
import { EstablishmentCompanyTransformer } from "@/features/companyBranches/services/establishmentCompanyTransformer";
import { AreaChart } from "lucide-react";
import { useEffect, useState } from "react";
import { DateRange } from "react-day-picker";
import ResumeList from "../../components/ResumeList";
import { totalizerFeesColumns, totalizerProductsColumns } from "../../constants/movementsTotalizerTableColumns.constant";
import { useMovementsTotalizer } from "../../hooks/useMovementsTotalizer";
import { MovementsTotalizerTransformer } from "../../services/movementsTotalizerTransformer";
import { MovementsTotalizerData } from "../../types/movementTotalizer.type";

const MovementsTotalizerList = () => {
  const { filterParams, setFilterParams } = useFilterParams();

  const [date, setDate] = useState<DateRange | undefined>({
    from: undefined,
    to: undefined,
  });
  const [searchEstablishmentCompany, setSearchEstablishmentCompany] = useDebounce('');

  useEffect(() => {
    if (!filterParams.fromInternalBase) {
      setFilterParams((prev) => ({ ...prev, fromInternalBase: 'false' }))
    }
  }, [filterParams.fromInternalBase])

  const { data: establishmentCompanyOptions } = useEstablishmentCompanies({
    filters: { search: searchEstablishmentCompany },
    mapper: Mapper.mapWithTransformer({ transformer: EstablishmentCompanyTransformer.optionTransformer() }),
  });

  const {
    data: movementsTotalizer,
    isFetching: movementsTotalizerIsFetching,
    error: movementsTotalizerError = {} as any,
    refetch: refetchMovementsTotalizer,
  } = useMovementsTotalizer<MovementsTotalizerData>({
    filters: {
      startDate: dateToISOStingTransformer(date?.from),
      endDate: dateToISOStingTransformer(date?.to),
      establishmentBranchId: filterParams.establishmentCompany?.value,
      fromInternalBase: filterParams.fromInternalBase || 'false',
    },
  });

  return (
    <div className="flex flex-col gap-6">
      <div className="flex">
        <ListHeader
          Icon={<AreaChart className="w-10 h-10" strokeWidth={1.5} />}
          title="Totalizadores"
        />
      </div>

      <ErrorMessageWrapper
        className="max-h-[calc(100vh-128px)]"
        statusCode={movementsTotalizerError?.status}
        refetchFunction={refetchMovementsTotalizer}
      >
        <div className="flex flex-col gap-6 max-h-[calc(100vh-128px)] overflow-y-auto pr-1 pt-1">
          <div className="flex items-center gap-2">
            <DatePickerWithRange
              date={date}
              setDate={setDate}
            />

            <ListFilters
              filters={[
                {
                  className: "z-52",
                  onChange: (e: any) => setFilterParams((params) => ({ ...params, establishmentCompany: e.value ? e : null })),
                  defaultValue: filterParams?.establishmentCompany,
                  onInputChange: setSearchEstablishmentCompany,
                  options: [{ label: "Todas", value: '' }, ...(establishmentCompanyOptions || [])],
                  placeholder: "Estabelecimento",
                },
                {
                  className: 'min-w-44',
                  onChange: (e) => setFilterParams((prev) => ({ ...prev, fromInternalBase: (e as Option).value, })),
                  defaultValue: filterParams.fromInternalBase || { label: 'Não', value: 'false' },
                  options: fromInternalBaseOptions,
                  placeholder: 'Exibir transações da base',
                }
              ]}
            />

          </div>
          <ResumeList
            data={movementsTotalizer}
          />

          <div className="space-y-2">
            <p>Produtos</p>
            <Table
              className={`max-h-[280px] min-h-[${Math.min(
                100 + (movementsTotalizer?.product_movements?.length || 0 * 56),
                256,
              )}px]`}
              columns={totalizerProductsColumns}
              isLoadingData={movementsTotalizerIsFetching}
              rows={MovementsTotalizerTransformer.productsTableTransformer()(movementsTotalizer?.product_movements) || []}
              rowEmptyValue="--"
              page={1}
              totalPages={1}
              totalCount={movementsTotalizer?.product_movements?.length}
            />
          </div>

          <div className="space-y-2">
            <p>Tarifas</p>
            <Table
              className={`max-h-[280px] min-h-[${Math.min(
                100 + (movementsTotalizer?.product_movements?.length || 0 * 56),
                256,
              )}px]`}
              columns={totalizerFeesColumns}
              isLoadingData={movementsTotalizerIsFetching}
              rows={MovementsTotalizerTransformer.feesTableTransformer()(movementsTotalizer?.fees) || []}
              rowEmptyValue="--"
              page={1}
              totalPages={1}
              totalCount={movementsTotalizer?.fees?.length}
            />
          </div>
        </div>
      </ErrorMessageWrapper>
    </div>
  );
};

export default MovementsTotalizerList;
