import MaskedTextField from "@/common/components/MaskedTextField";
import SelectController from "@/common/components/SelectController";
import TextField from "@/common/components/TextField";
import { customUnitMask } from "@/common/constants/masks.constant";
import { unitStrategy, unitTranslation } from "@/common/constants/unitStrategies.contants";
import useDebounce from "@/common/hooks/useDebounce";
import { CompanyEquipmentCategoriesTransformer } from "@/common/services/companyEquipmentCategoriesTransformer";
import { Mapper } from "@/common/services/mapper";
import { useEquipmentCategories } from "@/features/equipments/hooks/useEquipmentCategories";
import { SearchIcon } from "lucide-react";
import { Control, FieldErrors, UseFormRegister, UseFormSetValue, UseFormWatch } from "react-hook-form";
import { options as propertyKindOptions } from "../../constants/propertyKindOptions.constant";
import { options as usageKindOptions } from "../../constants/usageKindOptions.constant";
import { useEquipmentModels } from "../../hooks/useEquipmentModels";
import { EquipmentValidationSchema } from "../../schemas/create.schema";
import { EquipmentCategoryOption, EquipmentModelData } from "../../types/equipments.type";
import { FieldComponent } from "./FieldComponent";

interface EquipmentDetailsProps {
  control: Control<EquipmentValidationSchema, any>;
  register: UseFormRegister<EquipmentValidationSchema>;
  watch: UseFormWatch<EquipmentValidationSchema>;
  setValue: UseFormSetValue<EquipmentValidationSchema>;
  errors: FieldErrors<EquipmentValidationSchema>;
  disabled?: boolean;
  updateDisabled?: boolean
}

export const EquipmentDetails = ({
  control,
  register,
  watch,
  setValue,
  errors,
  disabled,
  updateDisabled,
}: EquipmentDetailsProps) => {
  const [searchEquipmentCategory, setSearchEquipmentCategory] = useDebounce('')
  const [searchEquipmentModel, setSearchEquipmentModel] = useDebounce('');

  const unitForConsumption = watch('unit_for_consumption')
  const equipmentCategory = watch('equipment_category.value')
  const supportsInfocarDataEnrichment = watch('supports_infocar_data_enrichment') ?? false

  const equipmentModelFields = watch('requiredFieldNames') || []

  const { data: equipmentCategories } = useEquipmentCategories<EquipmentCategoryOption[]>({
    filters: { search: searchEquipmentCategory },
    mapper: Mapper.mapWithTransformer({ transformer: CompanyEquipmentCategoriesTransformer.optionTransformer }),
  })

  const { data: equipmentModels } = useEquipmentModels({
    filters: { search: searchEquipmentModel },
    mapper: Mapper.mapToOptions<EquipmentModelData>({ labelFieldName: 'display_name', valueFieldName: 'id' }),
  })

  const handleChangeEquipmentCategory = (selectedOption: EquipmentCategoryOption) => {
    const { requiredFieldNames, supportsInfocarDataEnrichment, ...rest } = selectedOption

    setValue('equipment_category', rest as EquipmentValidationSchema['equipment_category'])
    setValue('equipment_model', null)
    setValue('requiredFieldNames', requiredFieldNames)
    setValue('supports_infocar_data_enrichment', supportsInfocarDataEnrichment)
  }

  return (
    <>
      <p>Detalhes do Equipamento</p>
      <SelectController
        name="equipment_category"
        control={control}
        label="Categoria do equipamento"
        placeholder={<SearchIcon />}
        markAsRequired
        onInputChange={(newValue) => setSearchEquipmentCategory(newValue)}
        defaultValue={watch('equipment_category')}
        options={equipmentCategories}
        onChange={(selectedOption) => handleChangeEquipmentCategory(selectedOption as EquipmentCategoryOption)}
        errorMessage={errors.equipment_category?.message}
        isDisabled={disabled || updateDisabled}
      />
      {supportsInfocarDataEnrichment && (
        <p className="text-sm font-light italic -mt-2">
          Informe a placa ou chassi e após criar o equipamento traremos todos os dados específicos.
        </p>
      )}
      {!!equipmentCategory && !supportsInfocarDataEnrichment && (
        <SelectController
          name="equipment_model"
          control={control}
          label="Busque por seu modelo por Código Fipe, Marca, Nome e ano"
          placeholder={<SearchIcon />}
          markAsRequired
          onInputChange={(newValue) => setSearchEquipmentModel(newValue)}
          defaultValue={watch('equipment_model')}
          options={equipmentModels}
          errorMessage={errors.equipment_model?.message}
          isDisabled={disabled || updateDisabled}
        />
      )}
      <div className="grid w-full grid-cols-2 gap-x-2 gap-y-4">
        {equipmentModelFields.map((field) => (
          <FieldComponent
            key={field.requiredFieldName}
            fieldName={field.requiredFieldName}
            register={register}
            watch={watch}
            errors={errors}
            disabled={disabled}
            updateDisabled={updateDisabled}
          />
        ))}
      </div>
      {unitForConsumption && (
        <MaskedTextField
          label={unitTranslation[unitForConsumption as keyof typeof unitTranslation]}
          mask={customUnitMask(unitStrategy[unitForConsumption as keyof typeof unitStrategy])}
          className="w-full"
          errorMessage={errors.last_usage_value?.message || undefined}
          disabled={true}
          defaultValue={watch('last_usage_value') || '0'}
        />
      )}
      <SelectController
        control={control}
        name="usage_kind"
        label="Tipo de uso"
        markAsRequired
        options={usageKindOptions}
        errorMessage={errors.usage_kind?.message || undefined}
        isDisabled={disabled}
      />
      <div className="flex gap-x-2">
        <SelectController
          control={control}
          name="property_kind"
          label="Tipo de propriedade"
          className="w-full"
          options={propertyKindOptions}
          errorMessage={errors.property_kind?.message || undefined}
          isDisabled={disabled}
        />
        <TextField
          label="Código proprietário"
          maxLength={64}
          className="w-full"
          {...register('property_code')}
          errorMessage={errors.property_code?.message || undefined}
          disabled={disabled}
        />
      </div>
    </>
  );
};
