import Box from "@/common/components/Box";
import { ImageWithPlaceholder } from "@/common/components/ImageWithPlaceholder";
import { BoxesIcon, BriefcaseBusinessIcon, ChevronDownIcon, ChevronUpIcon, FileBoxIcon } from "lucide-react";
import { formatCurrency } from "@/common/utils/formatters";
import ResumeItem from "@/common/components/ResumeItem";
import Button from "@/common/components/Button";
import { useState } from "react";
import { CompanyGovernmentAllocationDistribution } from "@/features/governmentAllocations/types/governmentAllocationDistribution.type";

const GovernmentAllocationDistributionBranchItem = ({ distribution }: { distribution: CompanyGovernmentAllocationDistribution; }) => {
  const [showProductCategoriesDistributions, setShowProductCategoriesDistributions] = useState(distribution.product_categories_distributions.length > 0);

  return (
    <Box className="flex flex-col gap-6">
      <div className="flex justify-between items-center">
        <div className="flex items-center gap-2">
          <ImageWithPlaceholder
            placeholderIcon={BriefcaseBusinessIcon}
            className="size-8"
            iconClassName="size-5"
            url={distribution.branch?.general_information?.company_logo?.url}
          />

          <p>{distribution.branch?.display_name}</p>
        </div>
      </div>

      <div className="flex items-end justify-between">
        <div className="flex items-end gap-4">
          <ResumeItem
            label="Limite total"
            value={formatCurrency(Number(distribution.total_value))}
            valueClassName="text-md"
          />

          <ResumeItem
            label="Total consumido"
            className="text-yellow-400"
            value={formatCurrency(Number(distribution.consumed_total))}
            valueClassName="text-md"
          />

          <ResumeItem
            label="Total disponível para uso"
            className="text-green-400"
            value={formatCurrency(Number(distribution.remaining_total))}
            valueClassName="text-md"
          />
        </div>

        {distribution.product_categories_distributions.length > 0 && (
          <Button
            variant="outline-error"
            className="italic font-light text-sm"
            onClick={() => setShowProductCategoriesDistributions(!showProductCategoriesDistributions)}
          >
            {showProductCategoriesDistributions ? 'Esconder serviços e produtos' : 'Mostrar serviços e produtos'}

            {showProductCategoriesDistributions ? <ChevronUpIcon strokeWidth={1.5} className="size-4" /> : <ChevronDownIcon strokeWidth={1.5} className="size-4" />}
          </Button>
        )}
      </div>

      {showProductCategoriesDistributions && (
        <div className="flex flex-col gap-4">
          {distribution.product_categories_distributions.map((productCategoryDistribution) => (
            <Box className="flex flex-col gap-3">
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-2">
                  <BoxesIcon strokeWidth={1} />

                  <p>{productCategoryDistribution.product_category.display_name}</p>
                </div>

                <div className="flex items-end gap-4 mx-6">
                  <ResumeItem
                    label="Valor limite"
                    value={formatCurrency(Number(productCategoryDistribution.total_value))}
                    valueClassName="text-md"
                    className="text-center"
                  />

                  <ResumeItem
                    label="Total consumido"
                    value={formatCurrency(Number(productCategoryDistribution.consumed_total))}
                    valueClassName="text-md"
                    className="text-center text-yellow-400"
                  />

                  <ResumeItem
                    label="Total disponível para uso"
                    value={formatCurrency(Number(productCategoryDistribution.remaining_total))}
                    valueClassName="text-md"
                    className="text-center text-green-400"
                  />
                </div>
              </div>

              {productCategoryDistribution.products_distributions.map((productDistribution) => (
                <Box className="py-3 px-3 text-white-400">
                  <div className="flex items-center justify-between border-s border-blue-800 px-3">
                    <div className="flex items-center gap-2">
                      <FileBoxIcon strokeWidth={1.5} />

                      <p>{productDistribution.product.display_name}</p>
                    </div>

                    <div className="flex items-end gap-4">
                      <ResumeItem
                        label="Valor limite"
                        value={formatCurrency(Number(productDistribution.total_value))}
                        valueClassName="text-md"
                        className="text-center"
                      />

                      <ResumeItem
                        label="Total consumido"
                        value={formatCurrency(Number(productDistribution.consumed_total))}
                        valueClassName="text-md"
                        className="text-center text-yellow-300"
                      />

                      <ResumeItem
                        label="Total disponível para uso"
                        value={formatCurrency(Number(productDistribution.remaining_total))}
                        valueClassName="text-md"
                        className="text-center text-green-300"
                      />
                    </div>
                  </div>
                </Box>
              ))}
            </Box>
          ))}
        </div>
      )}
    </Box>
  );
};

export default GovernmentAllocationDistributionBranchItem;
