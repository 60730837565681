import { z } from 'zod'

export const createGovernmentAllocationEntrySchema = z.object({
  office_number: z.string({ message: 'Campo Número do ofício é obrigatório' }).min(1, {
    message: 'Campo Número do ofício é obrigatório',
  }),
  total_value: z.string().min(1, {
    message: 'Campo Valor total é obrigatório',
  }),
  file: z
    .custom<File | string>()
    .optional()
    .refine((file) => !!file && typeof file !== 'string', {
      message: 'Campo Anexo é obrigatório',
    }),
  file_name: z.string().optional(),
})

export type CreateGovernmentAllocationValidationEntrySchema = z.infer<typeof createGovernmentAllocationEntrySchema>
