import { Option } from "@/common/types/option";
import { formatCurrency, formatDecimal } from "@/common/utils/formatters";
import { getFilenameFromUrl } from "@/common/utils/getFilenameFromUrl";
import { options as applicableProductsPeriodLimit } from "../constants/applicableProductsPeriodLimit.constant";
import { equipmentStatusOptions } from "../constants/equipmentStatusOptions.constant";
import { options as propertyKindOptions } from "../constants/propertyKindOptions.constant";
import { options as usageKindOptions } from "../constants/usageKindOptions.constant";
import { companyBranchDefaultPeriodRestrictionData } from "./companyBranchDefaultPeriodRestrictionData";
import { EquipmentData, RequiredFieldName } from "../types/equipments.type";
import { EquipmentValidationSchema } from "../schemas/create.schema";

export const companyEquipmentDefaultValues = ({
  equipmentData,
  companyBranchOptions,
}: {
  equipmentData?: EquipmentData,
  companyBranchOptions: Option[],
}) => {
  const defaultValues = {
    photos: equipmentData?.photos?.map((photo) => ({ id: photo.attachment_id, file: photo.url })) || [],
    status: equipmentData?.status
      ? equipmentStatusOptions.find((status) => status.value === equipmentData?.status)
      : '',
    branch_id: equipmentData?.branch.id
      ? companyBranchOptions?.find((branch) => branch.value === equipmentData?.branch.id)
      : '',
    equipment_category: equipmentData?.model?.category
      ? {
          label: equipmentData.model.category.display_name,
          value: equipmentData.model.category.id,
        }
      : undefined,
    unitForConsumption: equipmentData?.model?.category.unit_for_consumption,
    equipment_model: equipmentData?.model?.id
      ? Object.assign(
          {},
          {
            label: equipmentData.model.display_name,
            value: equipmentData.model.id,
          },
        )
      : '',
    requiredFieldNames: [],
    supports_infocar_data_enrichment: equipmentData?.model?.category?.supports_infocar_data_enrichment,
    unit_for_consumption: equipmentData?.model?.category?.unit_for_consumption || '',
    tank_capacity: equipmentData?.tank_capacity ? formatDecimal(equipmentData?.tank_capacity) : undefined,
    last_usage_value: equipmentData?.last_usage_value ? equipmentData?.last_usage_value.toString() : '',
    serial_number: equipmentData?.serial_number || '',
    official_name: equipmentData?.official_name || '',
    license_plate: equipmentData?.license_plate || '',
    chassis_number: equipmentData?.chassis_number || '',
    renavam: equipmentData?.renavam || '',
    usage_kind: equipmentData?.usage_kind
      ? usageKindOptions.find((usageKind) => usageKind.value === equipmentData?.usage_kind)
      : '',
    property_kind: equipmentData?.property_kind
      ? propertyKindOptions.find((propertyKind) => propertyKind.value === equipmentData?.property_kind)
      : '',
    property_code: equipmentData?.property_code || '',
    enablePeriodRestriction: !!equipmentData?.card?.restriction?.period_restrictions?.length,
    period_restrictions_attributes: equipmentData?.card?.restriction?.period_restrictions?.length
      ? companyBranchDefaultPeriodRestrictionData(equipmentData?.card?.restriction?.period_restrictions)
      : companyBranchDefaultPeriodRestrictionData(),
    documents:
      equipmentData?.documents?.map((document) => ({
        id: document.attachment_id,
        currentFileUrl: document.url,
        fileName: getFilenameFromUrl(document.url),
      })) || [],
    card_product_limits_attributes:
      equipmentData?.card?.card_product_limits?.map((cardProductLimit) => ({
        id: cardProductLimit.id,
        product_id: cardProductLimit.product.id,
        display_name: cardProductLimit.product.display_name,
        unit_for_quantity: cardProductLimit.product.unit_for_quantity,
        limitByTransaction:
          !!cardProductLimit.quantity_limit_per_transaction || !!cardProductLimit.credit_limit_per_transaction,
        limitByPeriod: !!cardProductLimit.quantity_limit_per_period || !!cardProductLimit.credit_limit_per_period,
        transactionLimit: {
          quantity: cardProductLimit.quantity_limit_per_transaction || '',
          totalValue: cardProductLimit.credit_limit_per_transaction
            ? formatCurrency(parseFloat(cardProductLimit.credit_limit_per_transaction))
            : '',
        },
        periodLimit: {
          period: cardProductLimit.period_in_days
            ? (applicableProductsPeriodLimit.find(
                (periodLimit) => periodLimit.value === `${cardProductLimit.period_in_days}`,
              ) as { label: string; value: '1' | '7' | '15' | '30' })
            : '',
          quantity: cardProductLimit.quantity_limit_per_period || '',
          totalValue: cardProductLimit.credit_limit_per_period
            ? formatCurrency(parseFloat(cardProductLimit.credit_limit_per_period))
            : '',
        },
      })) || [],
  } as EquipmentValidationSchema

  if (!!equipmentData?.model?.category?.supports_infocar_data_enrichment) {
    defaultValues.requiredFieldNames.push({ requiredFieldName: RequiredFieldName.InfocarFields })
  }

  if (equipmentData?.model?.category?.required_field_names?.length) {
    defaultValues.requiredFieldNames.push(
      ...equipmentData?.model?.category?.required_field_names?.map((requiredFieldName) => ({
        requiredFieldName,
      })),
    )
  }

  return defaultValues
}
