import { ImageWithPlaceholder } from '@/common/components/ImageWithPlaceholder'
import { governmentAllocationEntriesKind } from '@/common/constants/governmentAllocationEntriesKind.constant'
import { cn } from '@/common/utils/cn'
import { formatCurrency } from '@/common/utils/formatters'
import { governmentAllocationEntryStatusStrategy } from '@/features/governmentAllocations/pages/Show/constants/governmentAllocationEntryStatus.constant'
import { GovernmentAllocationEntriesResponse } from '@/features/governmentAllocations/types/governmentAllocationEntry.type'
import { ArrowBigRight, User2Icon } from 'lucide-react'
import { Link } from 'react-router-dom'

export class GovernmentAllocationDocumentsTransformer {
  static tableTransformer(companyId: string) {
    return (governmentAllocationEntries: GovernmentAllocationEntriesResponse['data']['data']) => {
      return governmentAllocationEntries.map((governmentAllocationEntry) => ({
        office_number: governmentAllocationEntry.office_number,
        total_value: (
          <p
            className={cn(
              'text-end',
              Number(governmentAllocationEntry.total_value) >= 0 ? 'text-valid' : 'text-danger-soft',
            )}
          >
            {formatCurrency(Number(governmentAllocationEntry.total_value))}
          </p>
        ),
        kind: governmentAllocationEntriesKind[
          governmentAllocationEntry.kind as keyof typeof governmentAllocationEntriesKind
        ],
        status:
          governmentAllocationEntryStatusStrategy[
            governmentAllocationEntry.status as keyof typeof governmentAllocationEntryStatusStrategy
          ],
        uploaded_at: 'Não implementado',
        requester: (
          <div className="flex items-center gap-2">
            <ImageWithPlaceholder
              alt="Solicitante"
              placeholderIcon={User2Icon}
              className="size-6"
              iconClassName="size-4"
            />
            <p className="text-xs">Não implementado</p>
          </div>
        ),
        responsible: (
          <div className="flex items-center gap-2">
            <ImageWithPlaceholder
              alt="Avaliador"
              placeholderIcon={User2Icon}
              className="size-6"
              iconClassName="size-4"
            />
            <p className="text-xs">Não implementado</p>
          </div>
        ),
        action: (
          <Link to={`/companies/${companyId}/government-allocations/${governmentAllocationEntry.id}/edit`}>
            <ArrowBigRight />
          </Link>
        ),
      }))
    }
  }
}
