import MaskedTextField from "@/common/components/MaskedTextField";
import TextField from "@/common/components/TextField";
import { FieldErrors, UseFormRegister, UseFormWatch } from "react-hook-form";
import { RequiredFieldName } from "../../types/equipments.type";
import { EquipmentValidationSchema } from "../../schemas/create.schema";
import { UnitTextField } from "@/common/components/UnitTextField";
import { unitStrategy } from "@/common/constants/unitStrategies.contants";

export const FieldComponent = ({
  fieldName,
  register,
  watch,
  errors,
  disabled,
  updateDisabled,
}: {
  fieldName: RequiredFieldName,
  register: UseFormRegister<EquipmentValidationSchema>,
  watch: UseFormWatch<EquipmentValidationSchema>,
  errors: FieldErrors<EquipmentValidationSchema>,
  disabled?: boolean;
  updateDisabled?: boolean;
}) => {
  const fieldMap = {
    infocar_fields: (
      <div className="flex w-full col-span-2 gap-x-4">
        <MaskedTextField
          label="Placa"
          markAsRequired={updateDisabled || !watch('chassis_number')}
          className="w-full"
          mask={["aaa0a00", "aaa0000"]}
          {...register('license_plate')}
          errorMessage={errors.license_plate?.message || undefined}
          disabled={disabled || (!updateDisabled && !!watch('chassis_number'))}
        />
        <p className="mt-8">Ou</p>
        <TextField
          label="Chassi"
          markAsRequired={!updateDisabled && !watch('license_plate')}
          className="w-full"
          maxLength={17}
          {...register('chassis_number')}
          errorMessage={errors.chassis_number?.message || undefined}
          disabled={disabled || updateDisabled || !!watch('license_plate')}
        />
      </div>
    ),
    official_name: (
      <TextField
        label="Nome"
        markAsRequired
        maxLength={64}
        {...register('official_name')}
        errorMessage={errors.official_name?.message || undefined}
        disabled={disabled || updateDisabled}
      />
    ),
    serial_number: (
      <TextField
        label="Número de série"
        markAsRequired
        maxLength={64}
        {...register('serial_number')}
        errorMessage={errors.serial_number?.message || undefined}
        disabled={disabled || updateDisabled}
      />
    ),
    license_plate: (
      <MaskedTextField
        label="Placa"
        markAsRequired
        mask={["aaa0a00", "aaa0000"]}
        {...register('license_plate')}
        errorMessage={errors.license_plate?.message || undefined}
        disabled={disabled || updateDisabled}
      />
    ),
    chassis_number: (
      <TextField
        label="Chassi"
        markAsRequired
        maxLength={17}
        {...register('chassis_number')}
        errorMessage={errors.chassis_number?.message || undefined}
        disabled={disabled || updateDisabled}
      />
    ),
    renavam: (
      <MaskedTextField
        label="Renavam"
        markAsRequired
        mask="00000000000"
        {...register('renavam')}
        errorMessage={errors.renavam?.message || undefined}
        disabled={disabled || updateDisabled}
      />
    ),
    tank_capacity: (
      <UnitTextField
        label="Capacidade do tanque"
        unit={unitStrategy['liters']}
        decimals={0}
        markAsRequired
        className="w-full"
        {...register('tank_capacity')}
        defaultValue={parseInt(watch('tank_capacity') || '0')}
        errorMessage={errors.tank_capacity?.message || undefined}
        disabled={disabled || updateDisabled}
      />
    ),
  };

  return fieldMap[fieldName];
};
