import { privateAxios } from '@/common/api/axios'
import { defaultTransformerResponse } from '@/common/constants/defaultTransformerResponse.constant'
import { Mapper } from '@/common/services/mapper'
import { MetaResponse } from '@/common/types/metaResponse'
import { keepPreviousData, useQuery } from '@tanstack/react-query'

type UseDashboardDataProps<T> = {
  filters: {
    startDate?: string
    endDate?: string
  }
  mapper?: (data: any) => T
}

export const useDashboardData = <T>({
  filters: { startDate, endDate },
  mapper = Mapper.default<T>(),
}: UseDashboardDataProps<T>) => {
  const {
    data: { data, meta } = defaultTransformerResponse<T>(),
    isPending,
    ...rest
  } = useQuery({
    queryKey: ['dashboardData', startDate, endDate],
    queryFn: () => {
      return privateAxios.get(`/dashboard/groups/mine`, {
        params: {
          'q[start_date_gteq]': startDate,
          'q[end_date_lteq]': endDate,
        },
      })
    },
    select: (data: { data: { data: T[]; meta: MetaResponse } }) => {
      return {
        data: mapper(data?.data?.data),
        meta: data?.data?.meta,
      }
    },
    placeholderData: keepPreviousData,
  })

  return { data, meta, isPending, ...rest }
}
