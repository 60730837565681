import { optionsToStrategy } from '@/common/utils/optionsToStrategy'

export enum GovernmentAllocationStatus {
  pending = 'pending',
  active = 'active',
}

export const governmentAllocationStatusOptions = [
  { value: GovernmentAllocationStatus.pending, label: 'Pendente' },
  { value: GovernmentAllocationStatus.active, label: 'Ativo' },
]

export const governmentAllocationStatusStrategy = optionsToStrategy(governmentAllocationStatusOptions)
