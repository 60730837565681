import Table from '@/common/components/Table';
import { mineTableColumns } from '@/features/reports/constants/mineTableColumns.constant';
import { useReportsMine } from '../hooks/useReportsMine';
import { PermittedReportsData } from '../types/permittedReports.type';
import { ReportsMineTransformer } from '@/features/reports/services/reportsMineTransformer';
import { Mapper } from '@/common/services/mapper';
import { ReportsMineData, ReportsMineTableData } from '@/features/reports/types/reportsMine.type';

interface ReportsMineTableProps {
  reportModel?: PermittedReportsData | null;
}

const ReportsMineTable = ({ reportModel }: ReportsMineTableProps) => {
  const { data: reportsMine, isLoading: reportsMineIsLoading } = useReportsMine({
    filters: {
      generatorKlass: reportModel?.generator_klass,
    },
    mapper: Mapper.mapWithTransformer<ReportsMineData[], ReportsMineTableData[]>({
      transformer: ReportsMineTransformer.tableTransformer(),
    }),
  });

  return (
    <Table
      columns={mineTableColumns}
      isLoadingData={reportsMineIsLoading}
      page={1}
      rows={reportsMine}
      totalPages={1}
      rowEmptyValue="--"
    />
  );
};

export default ReportsMineTable;
