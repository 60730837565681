import { formatCurrency, formatDate } from "@/common/utils/formatters";
import { ArrowBigRight } from "lucide-react";
import { BillData } from "../types/bills.type";
import { Link } from "react-router-dom";
import { billingBillStatus } from "@/common/constants/statusBills.constants";

export class BillsTransformer {
  static tableTransformer() {
    return (bills: BillData[]) => {
      return bills.map((bill) => ({
        ...bill,
        status: bill.status ? billingBillStatus.find(({ value }) => value === bill?.status)?.label : '',
        credit_info: bill?.granted_credit ? formatCurrency(parseFloat(`${bill?.granted_credit}`)) : undefined,
        total_amount: bill?.total_amount ? formatCurrency(parseFloat(`${bill?.total_amount}`)) : '',
        events_count: `${bill.events_count || 0} transações`,
        opened_at: bill.opened_at && formatDate(new Date(bill.opened_at)),
        closed_at: bill.closed_at && formatDate(new Date(bill.closed_at)),
        due_at: bill.due_at && formatDate(new Date(bill.due_at)),
        actions: <Link to={bill.id}><ArrowBigRight /></Link>,
      }));
    };
  }
}
