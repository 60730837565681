import { z } from 'zod';
import { unFormatCurrency, unFormatUnit } from '@/common/utils/unFormatters';
import { options as unityForQuantity } from "@/common/constants/unitForQuantityOptions.constant";
import { optionSchema } from '@/common/schemas/option.schema';
import { RequiredFieldName } from '@/features/equipments/types/equipments.type';

export const intervalsSchema = z.array(z.object({
  start_time: z.object({
    label: z.string().optional(),
    value: z.string().optional()
  }).optional().nullable(),
  end_time: z.object({
    label: z.string().optional(),
    value: z.string().optional()
  }).optional().nullable(),
}));

export const cardProductLimit = z.object({
  id: z.string().optional(),
  product_id: z.string().optional(),
  display_name: z.string().optional(),
  unit_for_quantity: z.string().optional(),
  limitByTransaction: z.boolean().optional(),
  limitByPeriod: z.boolean().optional(),
  transactionLimit: z.object({
    quantity: z.string().optional(),
    totalValue: z.string().optional(),
  }).optional(),
  periodLimit: z.object({
    period: z.object({
      label: z.string(),
      value: z.enum(['1', '7', '15', '30']),
    }).optional(),
    quantity: z.string().optional(),
    totalValue: z.string().optional(),
  }).optional(),
});

export type CardProductLimitValidationSchema = z.infer<typeof cardProductLimit>;

export const equipmentSchema = z.object({
  photos: z.array(
    z.object({
      id: z.string(),
      file: z.custom<string | File>()
    })
  ).optional(),
  branch_id: z.object({
    label: z.string(),
    value: z.string(),
  }, { message: 'Campo obrigatório' }),
  equipment_category: optionSchema,
  equipment_model: optionSchema.optional().nullable(),
  requiredFieldNames: z.array(
    z.object({
      requiredFieldName: z.custom<RequiredFieldName>(),
    }),
  ),
  unit_for_consumption: z.string().optional(),
  supports_infocar_data_enrichment: z.boolean().optional(),
  last_usage_value: z.string().optional(),
  official_name: z.string().optional(),
  serial_number: z.string().optional(),
  license_plate: z.string().optional(),
  chassis_number: z.string().optional(),
  renavam: z.string().optional(),
  tank_capacity: z.string().optional(),
  usage_kind: z.object({
    label: z.string(),
    value: z.string(),
  }, { message: 'Campo obrigatório' }),
  property_kind: z.object({
    label: z.string(),
    value: z.string(),
  }).optional(),
  property_code: z.string().optional(),
  enablePeriodRestriction: z.boolean().optional(),
  period_restrictions_attributes: z.array(z.object({
    id: z.string().optional(),
    week_day: z.string().optional(),
    blocked: z.boolean().optional(),
    intervals_attributes: intervalsSchema.optional(),
  })).optional(),
  documents: z.array(
    z.object({
      id: z.string(),
      file: z.custom<File>().optional(),
      fileName: z.string(),
      currentFileUrl: z.string(),
    })
  ).optional(),
  card_product_limits_attributes: z.array(
    cardProductLimit,
  ).optional(),
})
  .superRefine((data, ctx) => {
    if (!data?.equipment_model?.value) {
      return;
    }

    const licensePlateRegex = /[A-z]{3}\d[A-j0-9]\d{2}/;
    const chassisNumberRegex = /[A-HJ-NPR-Z0-9]{17}/;
    const renavamRegex = /\d{10,11}/;

    data.requiredFieldNames?.forEach(({ requiredFieldName }) => {
      if (requiredFieldName !== RequiredFieldName.InfocarFields && !data[requiredFieldName]) {
        ctx.addIssue({
          path: [requiredFieldName],
          code: z.ZodIssueCode.custom,
          message: 'Campo obrigatório',
        })
      }

      if (requiredFieldName === RequiredFieldName.InfocarFields) {
        if (!data.license_plate && !data.chassis_number) {
          ctx.addIssue({
            path: ['license_plate'],
            code: z.ZodIssueCode.custom,
            message: 'Preencha ao menos um dos campos',
          })
        }
      }

      if (
        requiredFieldName === 'license_plate' &&
        (!data.license_plate || !licensePlateRegex.test(data.license_plate))
      ) {
        ctx.addIssue({
          path: ['license_plate'],
          code: z.ZodIssueCode.custom,
          message: 'Placa inválida'
        });
      }

      if (
        requiredFieldName === 'chassis_number' &&
        (!data.chassis_number || !chassisNumberRegex.test(data.chassis_number))
      ) {
        ctx.addIssue({
          path: ['chassis_number'],
          code: z.ZodIssueCode.custom,
          message: 'Chassi inválido'
        });
      }

      if (requiredFieldName === 'renavam' && (!data.renavam || !renavamRegex.test(data.renavam))) {
        ctx.addIssue({
          path: ['renavam'],
          code: z.ZodIssueCode.custom,
          message: 'Renavam inválido'
        });
      }

      if (
        requiredFieldName === 'tank_capacity' &&
        (!data.tank_capacity || unFormatUnit(data.tank_capacity, 'L') <= 0)
      ) {
        ctx.addIssue({
          path: ['tank_capacity'],
          code: z.ZodIssueCode.custom,
          message: 'Capacidade do tanque inválido'
        });
      }
    });
  })
  .superRefine((data, ctx) => {
    if (!data?.enablePeriodRestriction) return;

    if (
      data?.period_restrictions_attributes?.length &&
      data.period_restrictions_attributes.every((periodRestriction) => {
        if (periodRestriction.blocked || periodRestriction.intervals_attributes?.some((interval) => interval.start_time?.value === 'all_day')) {
          return true;
        }
        return periodRestriction.intervals_attributes?.every((interval) => interval.start_time?.value && interval.end_time?.value);
      }) === false
    ) {
      ctx.addIssue({
        path: ['enablePeriodRestriction'],
        code: z.ZodIssueCode.custom,
        message: 'Caso opte por habilitar a restrição de período, preencha todos os campos'
      });
    }

    data.period_restrictions_attributes?.forEach((periodRestriction, index) => {
      if (periodRestriction.blocked || periodRestriction.intervals_attributes?.some((interval) => interval.start_time?.value === 'all_day')) return;

      periodRestriction.intervals_attributes?.forEach((interval, intervalIndex) => {
        if (interval.start_time?.value && interval.end_time?.value && interval.start_time?.value >= interval.end_time?.value) {
          ctx.addIssue({
            path: [`period_restrictions_attributes.${index}.intervals_attributes.${intervalIndex}.end_time`],
            code: z.ZodIssueCode.custom,
            message: 'O horário final deve ser maior que o inicial'
          });
        }
      });
    });
  })
  .superRefine((data, ctx) => {
    if (!data.card_product_limits_attributes?.length) {
      return;
    }
    data.card_product_limits_attributes.forEach((cardProductLimit, index) => {
      if (cardProductLimit.limitByTransaction) {
        if (
          (!cardProductLimit.transactionLimit?.quantity || !unFormatUnit(cardProductLimit.transactionLimit?.quantity, unityForQuantity.find((option) => option.value === cardProductLimit.unit_for_quantity)?.symbol || '')) &&
          (!cardProductLimit.transactionLimit?.totalValue || unFormatCurrency(cardProductLimit.transactionLimit?.totalValue) <= 0)
        ) {
          ctx.addIssue({
            path: [`card_product_limits_attributes.${index}.transactionLimit`],
            code: z.ZodIssueCode.custom,
            message: 'Caso opte por habilitar a restrição de limite por transação, preencha ao menos um dos campos'
          });
        }
      }
      if (cardProductLimit.limitByPeriod) {
        if (!cardProductLimit.periodLimit?.period?.value) {
          ctx.addIssue({
            path: [`card_product_limits_attributes.${index}.periodLimit.period`],
            code: z.ZodIssueCode.custom,
            message: 'Campo obrigatório'
          });
        }
        if (
          (!cardProductLimit.periodLimit?.quantity || !unFormatUnit(cardProductLimit.periodLimit?.quantity, unityForQuantity.find((option) => option.value === cardProductLimit.unit_for_quantity)?.symbol || '')) &&
          (!cardProductLimit.periodLimit?.totalValue || unFormatCurrency(cardProductLimit.periodLimit?.totalValue) <= 0)
        ) {
          ctx.addIssue({
            path: [`card_product_limits_attributes.${index}.periodLimit`],
            code: z.ZodIssueCode.custom,
            message: 'Caso opte por habilitar a restrição de limite por período, preencha ao menos um dos campos'
          });
        }
      }
    });
  });

export type EquipmentValidationSchema = z.infer<typeof equipmentSchema>;
