import { ChartData } from '@/common/types/chart.type'
import { barChartLabelFormatter } from '@/common/utils/barChartLabelFormatter'
import { cn } from '@/common/utils/cn'

export interface ValueChartProps {
  chartData: ChartData
  className?: string
}

export const ValueChart = ({ chartData, className }: ValueChartProps) => {
  return (
    <div
      className={cn('flex w-full bg-white-200 rounded-lg p-0 overflow-hidden', className)}
      style={{ gridColumn: `span ${chartData.width}` }}
    >
      <div className="flex flex-col gap-1 w-full bg-white-200 p-6">
        <p className="font-bold text-2xl">{chartData.title}</p>
        {chartData.subtitle && <p className="font-light text-[10px] leading-3">{chartData.subtitle}</p>}
      </div>
      <div className="flex flex-col gap-y-4">
        {chartData?.data?.map((value, index) => {
          return (
            <div key={index} className="flex items-center justify-center p-6">
              <p className="text-3xl font-bold mx-4">
                {barChartLabelFormatter(value[chartData.primary.query_identifier], chartData.primary.format_parser)}
              </p>
            </div>
          )
        })}
      </div>
    </div>
  )
}
