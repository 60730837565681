import { chartComponents } from '@/common/components/ChartRenderer/chartComponents'
import { ChartData } from '@/common/types/chart.type'
import { createElement } from 'react'

interface ChartRendererProps {
  chartData: ChartData
  className?: string
}

export const ChartRenderer = ({ chartData, className }: ChartRendererProps) => {
  return createElement(chartComponents[chartData.kind as keyof typeof chartComponents], { chartData, className })
}
