import Button from '@/common/components/Button'
import { FileUploader } from '@/common/components/FileUploader'
import Modal from '@/common/components/Modal'
import { ModalMessageSimple } from '@/common/components/ModalMessageSimple'
import TextField from '@/common/components/TextField'
import { formatCurrency } from '@/common/utils/formatters'
import { FormTransformers } from '@/common/utils/formTransformers'
import { transformToNumber } from '@/common/utils/transformers'
import { useCreateGovernmentAllocationEntry } from '@/features/governmentAllocations/hooks/useCreateGovernmentAllocationEntry'
import { GovernmentAllocationInfoItem } from '@/features/governmentAllocations/pages/Show/components/GovernmentAllocationInfoItem'
import {
  createGovernmentAllocationAnnulativeEntrySchema,
  CreateGovernmentAllocationAnnulativeEntryValidationSchema,
} from '@/features/governmentAllocations/schemas/createGovernmentAllocationAnnulativeEntry.schema'
import { GovernmentAllocationData } from '@/features/governmentAllocations/types/governmentAllocation.type'
import { zodResolver } from '@hookform/resolvers/zod'
import { useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'

interface CreateGovernmentAllocationAnnulativeEntryModalProps {
  governmentAllocation: GovernmentAllocationData
}

export const CreateGovernmentAllocationAnnulativeEntryModal = ({
  governmentAllocation,
}: CreateGovernmentAllocationAnnulativeEntryModalProps) => {
  const queryClient = useQueryClient()

  const [isOpen, setIsOpen] = useState(false)
  const [messages, setMessages] = useState<string[] | boolean>(false)

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    clearErrors,
    reset: resetForm,
    formState: { errors },
    setError,
  } = useForm<CreateGovernmentAllocationAnnulativeEntryValidationSchema>({
    resolver: zodResolver(createGovernmentAllocationAnnulativeEntrySchema),
  })

  const onSubmit: SubmitHandler<CreateGovernmentAllocationAnnulativeEntryValidationSchema> = (data) => {
    createGovernmentAllocationAnnulative({
      governmentAllocationId: governmentAllocation.id,
      form: data,
      kind: 'annulative',
      totalValue: transformToNumber({
        value: Number(governmentAllocation.total_value) - Number(governmentAllocation.consumed_total),
        invert: true,
      }),
    })
  }

  const onSuccess = () => {
    resetForm()
    queryClient.invalidateQueries({ queryKey: ['governmentAllocationEntries', governmentAllocation.id] })
    setIsOpen(false)
  }

  const onError = (error: any) => {
    if (error.response?.data.errors.base) {
      setMessages(error.response?.data.errors.base)
    } else {
      FormTransformers.errorsTransformer({ setError })(error)
    }
  }

  const {
    mutate: createGovernmentAllocationAnnulative,
    isPending: createGovernmentAllocationAnnulativeIsPending,
    isError: createGovernmentAllocationAnnulativeIsError,
  } = useCreateGovernmentAllocationEntry({
    onSuccess,
    onError,
  })

  const file = getValues('file')
  const fileName = getValues('file_name')

  return (
    <>
      <Modal
        isOpen={isOpen && !messages}
        setIsOpen={setIsOpen}
        onConfirm={handleSubmit(onSubmit)}
        disabled={createGovernmentAllocationAnnulativeIsPending}
        Trigger={<Button variant="outline-error">Solicitar anulação de saldo</Button>}
        titleClass="text-white-800 font-bold text-2xl"
        title="Anulação de saldo"
        footer="Enviar solicitação"
        body={
          <div className="flex flex-col gap-4">
            <GovernmentAllocationInfoItem
              label="Valor total empenhado"
              className="text-white-800"
              value={formatCurrency(Number(governmentAllocation.total_value))}
            />

            <GovernmentAllocationInfoItem
              label="Valor total consumido"
              className="text-yellow-400"
              value={formatCurrency(Number(governmentAllocation.consumed_total))}
            />

            <GovernmentAllocationInfoItem
              label="Valor bloqueado"
              className="text-danger-soft"
              value={formatCurrency(
                Number(governmentAllocation.total_value) - Number(governmentAllocation.consumed_total),
              )}
            />

            <form className="flex flex-col gap-4 text-white-800">
              <TextField
                label="Número do ofício da anulação de saldo"
                markAsRequired
                maxLength={24}
                errorMessage={errors?.office_number?.message}
                {...register('office_number')}
                disabled={createGovernmentAllocationAnnulativeIsPending}
              />

              <div>
                <FileUploader
                  name="file"
                  accept="application/pdf, image/png, image/jpg, image/jpeg"
                  title="Anexe o documento de anulação de saldo"
                  className="w-full bg-yellow-op-2 border-none"
                  fileName={fileName || undefined}
                  currentFileUrl={file ? URL.createObjectURL(file) : undefined}
                  handleChange={(file) => {
                    clearErrors('file')
                    setValue('file', file)
                    setValue('file_name', file.name)
                  }}
                  disabled={createGovernmentAllocationAnnulativeIsPending}
                />
                {errors.file?.message && <span className="text-danger-soft text-sm">{errors.file?.message}</span>}
              </div>
            </form>
          </div>
        }
      />

      {messages && (
        <ModalMessageSimple
          messages={messages}
          setMessages={setMessages}
          isError={createGovernmentAllocationAnnulativeIsError}
        />
      )}
    </>
  )
}
