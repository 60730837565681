import { privateAxios } from '@/common/api/axios'
import { defaultTransformerResponse } from '@/common/constants/defaultTransformerResponse.constant'
import { Mapper } from '@/common/services/mapper'
import { MetaResponse } from '@/common/types/metaResponse'
import { keepPreviousData, useQuery } from '@tanstack/react-query'

type UseBillingBillFiscalDocumentsProps<T> = {
  filters?: {
    id?: string
    search?: string
    startDate?: string
    endDate?: string
    page?: number
  }
  mapper?: (data: any) => T
}

export const useBillingBillFiscalDocuments = <T,>({
  filters: { id, search = '', startDate, endDate, page = 1 } = {},
  mapper = Mapper.default<T>(),
}: UseBillingBillFiscalDocumentsProps<T>) => {
  const { data: { data, meta } = defaultTransformerResponse<T>(), ...rest } = useQuery({
    queryKey: ['billingBillEvents', id, search, startDate, endDate, page],
    queryFn: () => {
      return privateAxios.get(`/billing/bills/${id}/fiscal_documents`, {
        params: {
          search,
          'q[created_at_gteq]': startDate,
          'q[created_at_lteq]': endDate,
          page,
        },
      })
    },
    select: (data: { data: { data: any[]; meta: MetaResponse } }) => {
      return {
        data: mapper(data?.data?.data),
        meta: data?.data?.meta,
      }
    },
    placeholderData: keepPreviousData,
  })

  return { data, meta, ...rest }
}
