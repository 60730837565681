import { ClientConsumptionData } from '@/common/types/client/consumption.type';

type RefundEventableProps = {
  data: {
    reason?: string;
    refundable: ClientConsumptionData;
  };
};

const RefundDetails = ({ data: refund }: RefundEventableProps) => {
  return (
    <div className="flex flex-col gap-3">
      <div className="flex gap-2 items-center">
        <p className="italic">N. Protocolo:</p>
        <p>{refund.refundable.protocol_number}</p>
      </div>

      <div className="flex gap-2 items-center">
        <p className="italic">Motivo:</p>
        <p>{refund.reason || '--'}</p>
      </div>
    </div>
  );
};

export default RefundDetails;
