import { actionButtonComponents } from '@/features/reports/constants/actionButton.constant';
import { ReportsMineData } from '@/features/reports/types/reportsMine.type';

export class ReportsMineTransformer {
  static tableTransformer() {
    return (reports: ReportsMineData[]) => {
      return reports.map((report) => {
        return {
          title: report.title,
          result_type: report.result_type,
          action: actionButtonComponents(report)[report?.status as keyof typeof actionButtonComponents],
        };
      });
    };
  }
}
