import { MetaResponse } from "@/common/types/metaResponse";
import { Option } from "@/common/types/option";
import { ProductData } from "@/common/types/product.type";

export enum RequiredFieldName {
  InfocarFields = 'infocar_fields',
  LicensePlate = 'license_plate',
  ChassisNumber = 'chassis_number',
  Renavam = 'renavam',
  SerialNumber = 'serial_number',
  OfficialName = 'official_name',
  TankCapacity = 'tank_capacity'
}

export type UnitForConsumption = 'kilometers' | 'hours'

export type EquipmentCategoryData = {
  id: string;
  description: string;
  display_name: string;
  required_field_names?: RequiredFieldName[];
  supports_infocar_data_enrichment: boolean
  unit_for_consumption: UnitForConsumption;
};

export type EquipmentModelData = {
  id: string;
  applicable_products: ProductData[];
  brand: string;
  category: EquipmentCategoryData;
  display_name: string;
  fipe_code?: string;
  kind: string;
  year?: number;
};

export type CompanyEquipmentPhoto = {
  id: string,
  file: File | string;
};

export type CardProductLimit = {
  id: string;
  credit_limit_per_period?: string;
  credit_limit_per_transaction?: string;
  period_in_days?: number;
  product: ProductData;
  quantity_limit_per_period?: string;
  quantity_limit_per_transaction?: string;
};

export type EquipmentDocument = {
  id: string;
  file?: File;
  fileName: string;
  currentFileUrl: string;
};

export type EquipmentCard = {
  id: string;
  created_at: string;
  expiration_month: number;
  expiration_year: number;
  last_four_digits: string;
  card_product_limits: CardProductLimit[];
  restriction: {
    period_restrictions: {
      id: string;
      blocked: boolean;
      intervals: {
        id: string;
        end_time: string;
        start_time: string;
      }[];
      week_day: 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday' | 'sunday';
    }[];
  };
  type: string;
  status: string;
};

export type EquipmentData = {
  id?: string;
  branch: {
    id?: string;
    client_users_count?: number;
    created_at?: string;
    display_name?: string;
    equipments_count?: number;
    parent_id?: string;
  };
  card?: EquipmentCard;
  created_at?: string;
  last_usage_at?: string;
  last_usage_value?: number;
  license_plate?: string;
  model?: EquipmentModelData;
  official_name?: string;
  serial_number?: string;
  chassis_number?: string;
  renavam?: string;
  tank_capacity?: string;
  usage_kind?: string;
  property_kind?: string;
  property_code?: string;
  photos: {
    attachment_id: string;
    id: string;
    url: string;
    content_type: string;
  }[];
  documents: {
    attachment_id: string;
    id: string;
    url: string;
    content_type: string;
  }[];
  status?: string;
};

export type EquipmentCategoryOption = Option & {
  requiredFieldNames: { requiredFieldName: RequiredFieldName }[]
  supportsInfocarDataEnrichment: boolean
}

export type EquipmentResponse = {
  data: {
    data: EquipmentData;
  };
};

export type EquipmentsResponse = {
  data: {
    data: EquipmentData[];
    meta: MetaResponse;
  };
};

export type EquipmentsTransformerResponse = {
  data: EquipmentData[];
  meta: MetaResponse;
};

export type EquipmentTransformerResponse = {
  data: EquipmentData;
};
