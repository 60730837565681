import usePrivateAxios from "@/common/api/usePrivateAxios";
import { getRailsUpdate } from "@/common/utils/getRailsUpdate";
import { mapToDeletion } from "@/common/utils/railsHelpers";
import { unFormatUnknownUnit } from "@/common/utils/unFormatters";
import { useMutation } from "@tanstack/react-query";
import { EquipmentValidationSchema } from "../schemas/create.schema";
import { EquipmentData } from "../types/equipments.type";
import { transformNewApplicableProducts, transformUpdatedApplicableProducts } from "../utils/applicableProductsTransformers";

interface UseUpdateEquipment {
  equipmentId?: string,
  onSuccess: () => void,
  onError: (error: any) => void,
}

const useUpdateEquipment = ({
  equipmentId,
  onSuccess,
  onError,
}: UseUpdateEquipment) => {
  const privateAxios = usePrivateAxios();

  return useMutation({
    mutationKey: ['updateCompanyEquipment'],
    mutationFn: async ({
      form,
      equipmentData,
    }: {
      form: EquipmentValidationSchema,
      equipmentData?: EquipmentData,
    }) => {
      const payload = {
        photos_attachments_attributes: getRailsUpdate(equipmentData?.photos || [], form.photos || [], (photo) => photo.attachment_id, (photo) => photo.id, (photo) => ({ id: photo.attachment_id, _destroy: true }), (photo) => ({ blob_id: photo.id })),
        branch_id: form.branch_id.value,
        card_attributes: {
          card_product_limits_attributes: [
            ...getRailsUpdate(equipmentData?.card?.card_product_limits || [], form.card_product_limits_attributes || [], (cardProductLimit) => cardProductLimit.product.id, (cardProductLimit) => cardProductLimit.product_id!, mapToDeletion, transformNewApplicableProducts),
            ...form.card_product_limits_attributes?.filter((cardProductLimit) => !!cardProductLimit.id)?.map(transformUpdatedApplicableProducts) || [],
          ].map(({ period_in_days, ...rest }) => rest),
          restriction_attributes: {
            period_restrictions_attributes: form.enablePeriodRestriction
              ? form.period_restrictions_attributes?.map((periodRestriction, index) => {
                return {
                  id: periodRestriction.id,
                  week_day: periodRestriction.week_day,
                  intervals_attributes: [
                    ...(() => {
                      const deletedIntervals = [] as { id: string, _destroy: boolean; }[];
                      equipmentData?.card?.restriction.period_restrictions?.[index]?.intervals?.forEach((interval) => {
                        deletedIntervals.push({
                          id: interval.id,
                          _destroy: true
                        });
                      });
                      return deletedIntervals;
                    })(),
                    ...(() => {
                      const newIntervals = [] as { start_time?: string | null, end_time?: string | null; }[];

                      if (periodRestriction.blocked) {
                        return [];
                      }

                      if (periodRestriction.intervals_attributes?.some((interval) => interval.start_time?.value === 'all_day')) {
                        newIntervals.push({ start_time: '00:00', end_time: '23:59' });
                        return newIntervals;
                      }

                      periodRestriction?.intervals_attributes?.forEach((interval) => {
                        newIntervals.push({ start_time: interval.start_time?.value, end_time: interval.end_time?.value });
                      });
                      return newIntervals;
                    })(),
                  ]
                };
              })
              : equipmentData?.card?.restriction?.period_restrictions?.length ? [
                ...equipmentData?.card?.restriction?.period_restrictions.map((periodRestriction) => ({
                  id: periodRestriction.id,
                  _destroy: true
                }))
              ] : undefined,
          },
        },
        category_id: form.equipment_category.value,
        model_id: form.equipment_model?.value,
        tank_capacity: form.tank_capacity ? unFormatUnknownUnit(form.tank_capacity) : undefined,
        last_usage_value: form.last_usage_value ? unFormatUnknownUnit(form.last_usage_value) : undefined,
        official_name: form.official_name,
        serial_number: form.serial_number,
        license_plate: form.license_plate,
        chassis_number: form.chassis_number,
        renavam: form.renavam,
        usage_kind: form.usage_kind.value,
        property_kind: form.property_kind?.value,
        property_code: form.property_code || undefined,
        documents_attachments_attributes: getRailsUpdate(equipmentData?.documents || [], form.documents || [], (document) => document.attachment_id, (equipmentDocument) => equipmentDocument.id, (document) => ({ id: document.attachment_id, _destroy: true }), (equipmentDocument) => ({ blob_id: equipmentDocument.id })),
      };

      return privateAxios.put(`/equipments/${equipmentId}`, { equipment: payload });
    },
    onSuccess,
    onError,
  });
};

export default useUpdateEquipment;
