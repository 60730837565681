import Table from '@/common/components/Table'
import { Mapper } from '@/common/services/mapper'
import { CreateGovernmentAllocationEntryModal } from '@/features/governmentAllocations/components/CreateGovernmentAllocationReinforcementModal'
import { useGovernmentAllocationEntries } from '@/features/governmentAllocations/hooks/useGovernmentAllocationEntries'
import { governmentAllocationDocumentsTableColumns } from '@/features/governmentAllocations/pages/Show/constants/governmentAllocationDocumentsTableColumns'
import { GovernmentAllocationDocumentsTransformer } from '@/features/governmentAllocations/services/governmentAllocationDocumentsTransformer'
import { GovernmentAllocationData } from '@/features/governmentAllocations/types/governmentAllocation.type'
import { useState } from 'react'
import { useParams } from 'react-router-dom'

type GovernmentAllocationDocumentsProps = {
  governmentAllocation: GovernmentAllocationData
  showReinforcementButton: boolean
  disableReinforcement: boolean
}

export const GovernmentAllocationDocuments = ({
  governmentAllocation,
  showReinforcementButton,
  disableReinforcement,
}: GovernmentAllocationDocumentsProps) => {
  const { companyId } = useParams()

  const [page, setPage] = useState(1)

  const {
    data: governmentAllocationEntries,
    isLoading: governmentAllocationEntriesIsLoading,
    meta,
  } = useGovernmentAllocationEntries({
    governmentAllocationId: governmentAllocation.id,
    filters: {
      page,
    },
    mapper: Mapper.mapWithTransformer({
      transformer: GovernmentAllocationDocumentsTransformer.tableTransformer(companyId || ''),
    }),
  })

  return (
    <div className="flex flex-col gap-6">
      {showReinforcementButton && (
        <div className="self-end">
          <CreateGovernmentAllocationEntryModal
            governmentAllocationId={governmentAllocation.id}
            disabled={disableReinforcement}
          />
        </div>
      )}

      <Table
        className="min-h-[256px] max-h-[calc(100vh-496px)]"
        columns={governmentAllocationDocumentsTableColumns}
        rows={governmentAllocationEntries}
        hasPagination
        page={meta.current_page}
        setPage={(p) => setPage(p)}
        totalCount={meta.total}
        totalPages={meta.total_pages}
        isLoadingData={governmentAllocationEntriesIsLoading}
        rowEmptyValue="--"
      />
    </div>
  )
}
