import usePrivateAxios from "@/common/api/usePrivateAxios";
import { BillData, BillResponse } from "@/features/billingBills/types/bills.type";
import { UseQueryResult, useQuery } from "@tanstack/react-query";

type useBillingBillProps = {
  id?: string;
};

type useBillingBillType = ({ id }: useBillingBillProps) => UseQueryResult<BillData, unknown>;

const useBillingBill: useBillingBillType = ({ id }) => {
  const privateAxios = usePrivateAxios();

  return useQuery<BillResponse, unknown, BillData>({
    queryKey: ['billingBill', id],
    queryFn: () => {
      return privateAxios.get(`/billing/bills/${id}`);
    },
    select: (data) => data?.data?.data,
  });
};

export default useBillingBill;
