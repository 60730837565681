import Box from '@/common/components/Box';
import { useCompanyBranches } from '@/common/hooks/queries/useCompanyBranches';
import { useGovernmentAllocationsDistributions } from '@/features/governmentAllocations/hooks/useGovernmentAllocationsDistributions';
import { useParams } from 'react-router-dom';
import GovernmentAllocationDistributionBranchItem from './GovernmentAllocationDistributionBranchItem';
import { CompanyGovernmentAllocationDistribution } from '@/features/governmentAllocations/types/governmentAllocationDistribution.type';

export const GovernmentAllocationDistribution = () => {
  const { id } = useParams();

  const { meta: companyBranchesMeta } = useCompanyBranches({});

  const { data: governmentAllocationDistributions, meta: governmentAllocationDistributionsMeta } = useGovernmentAllocationsDistributions<CompanyGovernmentAllocationDistribution[]>({
    governmentAllocationId: id,
  });

  return (
    <Box className="flex flex-col gap-4">
      <p>Divisão de crédito do empenho</p>

      <p className="text-sm italic font-light">{governmentAllocationDistributionsMeta.total}/{companyBranchesMeta.total} Departamentos adicionados</p>

      <div className="flex flex-col gap-4">
        {governmentAllocationDistributions?.map((distribution) => (
          <GovernmentAllocationDistributionBranchItem
            key={distribution.id}
            distribution={distribution}
          />
        ))}
      </div>
    </Box>
  );
};
