import usePrivateAxios from '@/common/api/usePrivateAxios'
import { UserData, UserResponse } from '@/common/types/users'
import { UseQueryResult, useQuery } from '@tanstack/react-query'

interface UseCurrentUserProps {
  staleTime?: number
}

export const useCurrentUser: (props: UseCurrentUserProps) => UseQueryResult<UserData, unknown> = ({ staleTime }) => {
  const privateAxios = usePrivateAxios()

  return useQuery<UserResponse, unknown, UserData>({
    queryKey: ['currentUser'],
    queryFn: () => {
      return privateAxios.get('/users/me')
    },
    select: (data) => data?.data?.data,
    refetchInterval: 1000 * 60 * 5, // 5 minutes
    refetchIntervalInBackground: true,
    staleTime,
  })
}
