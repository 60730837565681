export default [
  {label: 'Status', value: 'status' },
  {label: 'Transação', value: 'protocol_number' },
  {label: 'Tipo', value: 'eventable_type' },
  {label: 'Detalhes', value: 'details'},
  {label: 'Placa do Veículo', value: 'license_plate'},
  {label: 'Vlr. Total', value: 'total_amount' },
  {label: 'Tarifas', value: 'fee_total_amount' },
  {label: 'Ret. IR', value: 'income_tax_total_amount' },
  {label: 'Filial', value: 'branch' },
  {label: 'Cartão', value: 'card' },
  {label: 'Un/Consumo Informado', value: 'usage_declaration' },
  {label: 'Autor', value: 'author' },
  {label: 'Data e Hora', value: 'created_at'},
]
