import { cn } from "@/common/utils/cn"

type GovernmentAllocationInfoItemProps = {
  label: string
  value: string | number
  footer?: JSX.Element
  className?: string
}

export const GovernmentAllocationInfoItem = ({
  label,
  value,
  footer,
  className
}: GovernmentAllocationInfoItemProps) => {
  return (
    <div className={cn("flex flex-col", className)}>
      <p className="text-sm font-light italic">{label}</p>
      <p className="text-lg font-bold leading-5">{value}</p>
      {footer}
    </div>
  )
}
