import { ArrowBigRight } from 'lucide-react';
import { Link } from 'react-router-dom';
import { PermittedReportsData } from '../types/permittedReports.type';

export class PermittedReportsTransformer {
  static tableTransformer() {
    return (permittedReports: PermittedReportsData[]) => {
      return permittedReports.map((permittedReport) => {
        return {
          ...permittedReport,
          action: (
            <Link to={`/reports/create?generator_klass=${permittedReport.generator_klass}`}>
              <ArrowBigRight />
            </Link>
          ),
        };
      });
    };
  }
}
