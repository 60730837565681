export const cardStatusTranslation = {
  'pending': 'Pendente',
  'awaiting_approval': 'Aguardando aprovação',
  'printed': 'Impresso',
  'validated': 'Validado',
  'shipped': 'Enviado',
  'active': 'Ativo',
  'blocked': 'Bloqueado',
  'replaced': 'Substituído',
};
