import Box from '@/common/components/Box'
import Button from '@/common/components/Button'
import Tabs from '@/common/components/Tabs'
import { useGoBack } from '@/common/hooks/useGoBack'
import { cn } from '@/common/utils/cn'
import { formatCurrency, formatDateRange } from '@/common/utils/formatters'
import { useMineCompany } from '@/features/company/hooks/useMineCompany'
import { CreateGovernmentAllocationAnnulativeEntryModal } from '@/features/governmentAllocations/components/CreateGovernmentAllocationAnnulativeEntryModal'
import { useGovernmentAllocation } from '@/features/governmentAllocations/hooks/useGovernmentAllocation'
import { GovernmentAllocationDistribution } from '@/features/governmentAllocations/pages/Show/components/GovernmentAllocationDistribution'
import { GovernmentAllocationDocuments } from '@/features/governmentAllocations/pages/Show/components/GovernmentAllocationDocuments'
import { GovernmentAllocationInfoItem } from '@/features/governmentAllocations/pages/Show/components/GovernmentAllocationInfoItem'
import { governmentAllocationStatusStrategy } from '@/features/governmentAllocations/pages/Show/constants/governmentAllocationStatus.constant'
import { isAfter, isWithinInterval } from 'date-fns'
import { ChevronLeftIcon, ChevronsRightIcon, FileCheckIcon, FileClockIcon, OctagonAlertIcon } from 'lucide-react'
import { useMemo } from 'react'
import { Link, useParams } from 'react-router-dom'

const Show = () => {
  const { id } = useParams()
  const goBack = useGoBack()

  const { data: company } = useMineCompany()

  const { data: governmentAllocation } = useGovernmentAllocation(id || '')

  const isInPeriod = useMemo(() => {
    return governmentAllocation
      ? isWithinInterval(new Date(), {
          start: new Date(governmentAllocation?.start_of_document_validity),
          end: new Date(governmentAllocation?.end_of_document_validity),
        })
      : false
  }, [governmentAllocation?.start_of_document_validity, governmentAllocation?.end_of_document_validity])

  const isExpired = useMemo(() => {
    return governmentAllocation ? isAfter(new Date(), new Date(governmentAllocation?.end_of_document_validity)) : false
  }, [governmentAllocation?.end_of_document_validity])

  if (!governmentAllocation) {
    return <Box>Carregando...</Box>
  }

  return (
    <>
      <div className="flex justify-between items-center mb-6">
        <div className="flex gap-4 items-center">
          <Button variant="link-white" onClick={() => goBack({ fallback: `government-allocation` })}>
            <ChevronLeftIcon size={40} strokeWidth={1} />
          </Button>

          <div className="flex items-center gap-1">
            <p className="text-white-400">{company?.matrix_branch?.general_information?.fantasy_name}</p>

            <ChevronsRightIcon className="text-white-400" />

            <p className="text-white-800">{governmentAllocation.main_entry.office_number}</p>
          </div>
        </div>
      </div>

      <Box className="flex flex-col gap-6 max-h-[calc(100vh-128px)] overflow-y-auto">
        <div className="flex justify-between">
          <div className="flex items-end gap-2">
            <p className="text-lg font-bold leading-5">Empenho</p>
            <Link to={`/government-allocations/${governmentAllocation.main_entry.id}/edit`}>
              <Button variant="link">Ver contrato</Button>
            </Link>
          </div>

          <div className="flex items-center gap-2">
            <div className="flex items-center gap-2">
              {isExpired ? (
                <FileClockIcon strokeWidth={1} className="size-6 text-danger" />
              ) : (
                <FileCheckIcon strokeWidth={1} className="size-6 text-success" />
              )}
              <p className={cn('text-xs font-bold leading-3', isExpired ? 'text-danger-soft' : 'text-valid')}>
                {isExpired ? 'Expirado' : 'Vigente'}
              </p>
            </div>
            {isExpired && (
              <CreateGovernmentAllocationAnnulativeEntryModal governmentAllocation={governmentAllocation} />
            )}
          </div>
        </div>
        <div className="flex items-start gap-6">
          <GovernmentAllocationInfoItem
            label="Status"
            value={
              governmentAllocationStatusStrategy[
                governmentAllocation.status as keyof typeof governmentAllocationStatusStrategy
              ]
            }
          />

          <GovernmentAllocationInfoItem label="Nº Empenho" value={governmentAllocation.main_entry.office_number} />

          <GovernmentAllocationInfoItem
            label="Período de competência"
            value={formatDateRange(
              new Date(governmentAllocation.start_of_document_validity),
              new Date(governmentAllocation.end_of_document_validity),
            )}
          />

          <GovernmentAllocationInfoItem label="Tipo de controle de empenho" value="Por valor em R$" />
        </div>
        <div className="flex items-start gap-6">
          <GovernmentAllocationInfoItem
            label="Valor total empenhado"
            value={formatCurrency(Number(governmentAllocation.total_value))}
          />

          <GovernmentAllocationInfoItem
            label="Valor total aprovado"
            value={formatCurrency(Number(governmentAllocation.approved_value))}
          />

          <GovernmentAllocationInfoItem
            label="Restante a aprovar"
            value={formatCurrency(
              Number(governmentAllocation.total_value) - Number(governmentAllocation.approved_value),
            )}
          />

          <GovernmentAllocationInfoItem
            label="Valor total consumido"
            value={formatCurrency(Number(governmentAllocation.consumed_total))}
            className="text-yellow-400"
          />

          <div className="flex items-start gap-2">
            {isExpired && <OctagonAlertIcon strokeWidth={2} className="size-5" color="#FBBCB6" />}

            <GovernmentAllocationInfoItem
              label={isExpired ? 'Valor total bloqueado' : 'Valor total disponível'}
              value={formatCurrency(Number(governmentAllocation.remaining_total))}
              className={cn(isExpired ? 'text-danger-soft' : 'text-valid')}
            />
          </div>
        </div>

        <Tabs
          tabsListClassName="w-full bg-transparent rounded-none"
          tabsTriggerClassName="h-8 w-full rounded-none border-b border-[#444444] text-white-800 text-xs data-[state=active]:bg-transparent data-[state=active]:text-white-800 data-[state=active]:shadow-none data-[state=active]:border-blue-800"
          tabsContentClassName="mt-6"
          tabsList={[
            {
              label: 'Documentos de empenho',
              value: '',
              body: (
                <GovernmentAllocationDocuments
                  governmentAllocation={governmentAllocation}
                  showReinforcementButton={!isExpired}
                  disableReinforcement={!isInPeriod}
                />
              ),
            },
            {
              label: 'Divisão de crédito do empenho',
              value: 'distribution',
              body: <GovernmentAllocationDistribution />,
            },
            {
              label: 'Resumo do empenho',
              value: 'governmentAllocationSummary',
              body: <></>,
            },
          ]}
        />
      </Box>
    </>
  )
}

export default Show
