import { cn } from '@/common/utils/cn'
import { GovernmentAllocationCardDetails } from '@/features/governmentAllocations/components/GovernmentAllocationCardDetails'
import { governmentAllocationStatusStyle } from '@/features/governmentAllocations/constants/governmentAllocationStatusStyle.constant'
import {
  getBarLeftPosition,
  getCardLeftPosition,
  getCardRightPosition,
} from '@/features/governmentAllocations/services/getLayoutGovernmentAllocation'
import { GovernmentAllocationData } from '@/features/governmentAllocations/types/governmentAllocation.type'
import { isSameMonth, isWithinInterval } from 'date-fns'
import { Link } from 'react-router-dom'
import { Tooltip } from 'react-tooltip'

type GovernmentAllocationMonthDetailsProps = {
  governmentAllocation: GovernmentAllocationData
  year: number
  month: number
}

export const GovernmentAllocationMonthDetails = ({
  governmentAllocation,
  year,
  month,
}: GovernmentAllocationMonthDetailsProps) => {
  const applicableStartDate = new Date(governmentAllocation.applicable_start_date)
  const applicableEndDate = new Date(governmentAllocation.applicable_end_date)
  const isActualMonth = isSameMonth(new Date(year, month), new Date())

  const showGovernmentAllocation =
    isSameMonth(applicableStartDate, new Date(year, month)) ||
    isWithinInterval(new Date(year, month), {
      start: new Date(governmentAllocation.applicable_start_date),
      end: new Date(governmentAllocation.applicable_end_date),
    })

  return (
    <>
      <div className="relative h-[120px]">
        <div
          className={cn(
            'absolute flex items-center w-[calc(100%+1px)] h-[120px] overflow-hidden',
            month === 11 && 'w-full',
          )}
        >
          {showGovernmentAllocation && (
            <Link
              to={
                governmentAllocation.main_entry.status === 'completed'
                  ? `/government-allocations/${governmentAllocation.id}`
                  : `/government-allocations/${governmentAllocation.main_entry.id}/edit`
              }
              data-tooltip-id={`${governmentAllocation.id}-${year}-${month}`}
              className={cn(
                'absolute rounded-lg border-2 border-transparent overflow-hidden cursor-pointer',
                governmentAllocationStatusStyle.card[
                  governmentAllocation.status as keyof typeof governmentAllocationStatusStyle.card
                ],
              )}
              style={{
                left: getCardLeftPosition({ applicableStartDate, year, month }),
                right: getCardRightPosition({ applicableEndDate, year, month }),
              }}
            >
              <GovernmentAllocationCardDetails governmentAllocation={governmentAllocation} />
            </Link>
          )}

          {isActualMonth && (
            <div className="absolute top-0 bottom-0 w-px bg-yellow-600" style={{ left: getBarLeftPosition() }} />
          )}
        </div>
      </div>

      <Tooltip
        id={`${governmentAllocation.id}-${year}-${month}`}
        place="right"
        arrowColor="transparent"
        className={cn(
          'rounded-lg border-2 border-transparent p-0 z-10',
          governmentAllocationStatusStyle.tooltip[
            governmentAllocation.status as keyof typeof governmentAllocationStatusStyle.tooltip
          ],
        )}
      >
        <GovernmentAllocationCardDetails governmentAllocation={governmentAllocation} />
      </Tooltip>
    </>
  )
}
