import Box from '@/common/components/Box';
import { CopyButton } from '@/common/components/CopyButton';
import { ImageWithPlaceholder } from '@/common/components/ImageWithPlaceholder';
import Select from '@/common/components/Select';
import TextField from '@/common/components/TextField';
import { userStatusTranslation } from '@/common/constants/userStatus.constants';
import { useCardWithSecureInformation } from '@/common/hooks/queries/useCardWithSecureInformation';
import { useCopyButton } from '@/common/hooks/useCopyButton';
import { useCurrentUser } from '@/common/hooks/useCurrentUser';
import { dateTransformer } from '@/common/utils/dateTransformer';
import { formatCpf, formatPhoneNumber } from '@/common/utils/formatters';
import { CreateClientUserCard } from '@/features/users/components/CreateClientUserCard';
import { ConsumptionLimit } from '@/features/users/components/showUser/ConsumptionLimit';
import { useClientUser } from '@/features/users/hooks/useClientUser';
import { useParams } from 'react-router-dom';
import { UserRoundIcon } from "lucide-react";
import RequestPasswordReset from '@/features/users/components/RequestPasswordReset';
import RequestConsumptionPasswordReset from '@/features/users/components/RequestConsumptionPasswordReset';

const GeneralInformationTab = () => {
  const { id: userId } = useParams();

  const { data: userMe } = useCurrentUser({});

  const { data: clientUserData } = useClientUser({ id: userId || '' });

  const userPermissionsSet = new Set(userMe?.permissions?.map(({ operation_klass }) => operation_klass));

  const { control, copySuccess, copyFailed } = useCopyButton();

  const {
    mutate: personalCard,
    isPending: personalCardIsPending,
  } = useCardWithSecureInformation({
    onSuccess: (response) => copySuccess(response?.data?.data?.number),
    onError: copyFailed
  });

  return (
    <>
      <Box className="flex flex-col gap-4 shadow-[4px_4px_8px_4px_rgba(0,_0,_0,_0.2)] overflow-y-auto max-h-[calc(100vh-192px)]">
        <p className="text-lg font-bold">Dados pessoais</p>
        <div className="flex flex-col gap-2">
          <p className="text-xs">Foto</p>
          <ImageWithPlaceholder
            url={clientUserData?.profile_picture?.url}
            alt={clientUserData?.full_name}
            className="size-20"
            iconClassName="size-16"
            placeholderIcon={UserRoundIcon}
          />
        </div>
        <div className="flex gap-2 w-full justify-between">
          <TextField
            label="CPF"
            className="w-3/4"
            value={
              clientUserData?.cpf
                ? formatCpf(clientUserData?.cpf)
                : ''
            }
            disabled
          />
          <TextField
            label="Data de nascimento"
            className="w-1/4"
            value={
              clientUserData?.date_of_birth
                ? dateTransformer(clientUserData?.date_of_birth)
                : ''
            }
            disabled
          />
        </div>
        <div className="flex gap-2 w-full justify-between">
          <TextField
            label="Nome"
            className="w-3/4"
            value={clientUserData?.full_name}
            disabled
          />
          <Select
            label="Perfil"
            className="w-1/4"
            value={clientUserData?.user_roles?.map(({ role }) => ({
              label: role.display_name,
              value: role.id,
            }))}
            isMulti
            isDisabled
          />
        </div>
        <TextField
          label="Status"
          value={
            userStatusTranslation[
            (clientUserData?.status ||
              'default') as keyof typeof userStatusTranslation
            ]
          }
          disabled
        />
        <TextField
          label="Matrícula"
          value={clientUserData?.enrollment_number}
          disabled
        />
        <TextField
          label="Telefone"
          value={clientUserData?.phone?.number ? formatPhoneNumber(clientUserData?.phone?.number) : ''}
          disabled
        />
        <TextField
          label="Email"
          value={clientUserData?.email}
          disabled
        />
        <div className="w-full rounded-sm p-4 bg-white-100">
          {!clientUserData?.cards?.length ? (
            <div className="flex w-full items-center justify-between">
              <p>Cartão personal</p>
              <CreateClientUserCard clientUserId={userId} />
            </div>
          ) : (
            <div className="flex flex-col gap-4">
              <p>Cartão personal</p>
              <TextField
                disabled
                label="Código"
                RightIcon={!personalCardIsPending ? <CopyButton control={control} className="text-blue-800" /> : undefined}
                onIconClick={() => personalCard(clientUserData?.cards[0].id)}
                isLoading={personalCardIsPending}
                value={clientUserData?.cards[0]?.last_four_digits ? `**** **** **** ${clientUserData?.cards[0]?.last_four_digits}` : ''}
              />
              {(clientUserData?.cards?.[0]?.limit_per_period || clientUserData?.cards?.[0]?.limit_per_transaction) && (
                <ConsumptionLimit userId={clientUserData?.id} card={clientUserData?.cards?.[0]} />
              )}
            </div>
          )}
        </div>

        {clientUserData && userMe?.id !== userId && userPermissionsSet.has('Client::Users::RequestPasswordReset') && (
          <div className="w-full rounded-sm p-4 bg-white-100">
            <div className="flex w-full items-center justify-between">
              <p>Senha</p>

              <RequestPasswordReset id={userId!} />
            </div>
          </div>
        )}

        {clientUserData && userPermissionsSet.has('Client::Users::RequestConsumptionPasswordReset') && (
          <div className="w-full rounded-sm p-4 bg-white-100">
            <div className="flex w-full items-center justify-between">
              <p>Senha de consumo</p>

              <RequestConsumptionPasswordReset id={userId!} />
            </div>
          </div>
        )}
      </Box>
    </>
  );
};

export default GeneralInformationTab;
