import { formatCurrency } from '@/common/utils/formatters'

const formatters = {
  currency: formatCurrency,
  integer: (v: number) => v,
}

export const barChartLabelFormatter = (v: string | number, format: string) => {
  if (Number(v) === 0) return ''

  const formatter = formatters[(format || 'integer') as keyof typeof formatters]
  const formattedValue = formatter ? formatter(Number(v)) : v

  return `${formattedValue}`
}
