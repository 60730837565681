import Modal from '@/common/components/Modal';
import { twMerge } from 'tailwind-merge';

interface ModalMessageSimpleProps {
  messages: string[] | boolean;
  setMessages: React.Dispatch<React.SetStateAction<string[] | boolean>>;
  onClose?: () => void;
  title?: string;
  footer?: string;
  message?: string;
  isError?: boolean;
}

export const ModalMessageSimple = ({ messages, setMessages, onClose, title, footer, isError }: ModalMessageSimpleProps) => {
  return (
    <Modal
      isOpen={!!messages}
      setIsOpen={setMessages}
      onCancel={onClose}
      onConfirm={() => {
        setMessages(false);
        onClose?.();
      }}
      title={title || ' '}
      footer={footer || 'Entendido!'}
      titleClass="text-danger-soft"
      showClose={false}
      subtitle={(
        <div className='flex flex-col gap-3'>
          {typeof messages === 'object' && messages?.map((message, index) => (
            <p key={index} className={twMerge("text-[16px]", isError && "text-danger-soft")}>
              {message}
            </p>
          ))}
        </div>
      )}
    />
  );
};
