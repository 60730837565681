import { Option } from "@/common/types/option";
import { formatCurrency } from "@/common/utils/formatters";
import { CompanyBranchData } from "@/features/company/types/company.type";
import { companyBranchDefaultPeriodRestrictionData } from "@/features/equipments/utils/companyBranchDefaultPeriodRestrictionData";

export const companyBranchDefaultValues = ({
  branchData,
  companyBranchOptions,
}: {
  branchData?: CompanyBranchData,
  companyBranchOptions: Option[],
}) => ({
  parent: branchData?.parent_id ?
    companyBranchOptions?.find((branch) => branch.value === branchData?.parent_id) :
    undefined,
  display_name: branchData?.display_name,
  master_client_user_id: branchData?.master_client_user?.id ?
    {
      label: branchData?.master_client_user.full_name,
      value: branchData?.master_client_user.id
    } : undefined,
  general_information_attributes: {
    willUseOwnData: !!branchData?.general_information ? true : false,
    ...branchData?.general_information,
    address_attributes: {
      ...branchData?.general_information?.address,

    }
  },
  enablePeriodRestriction: !!branchData?.restriction?.period_restrictions.length,
  period_restrictions_attributes: companyBranchDefaultPeriodRestrictionData(branchData?.restriction?.period_restrictions),
  granted_credit: formatCurrency(Number(branchData?.granted_credit)),
  enableCreditConfiguration: !!branchData?.product_category_granted_credits?.length,
  product_category_granted_credits_attributes: branchData?.product_category_granted_credits?.map((category) => ({
    id: category.id,
    granted_credit: formatCurrency(Number(category.granted_credit)),
    product_category_id: category.product_category.id,
    product_granted_credits_attributes: category.product_granted_credits?.map((product) => ({
      id: product.id,
      product_id: product.product.id,
      granted_credit: formatCurrency(Number(product.granted_credit)),
    })),
  })),
});
