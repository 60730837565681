import { useMutation } from "@tanstack/react-query";
import usePrivateAxios from "@/common/api/usePrivateAxios";
import { AxiosError } from "axios";
import { EquipmentBlockValidationSchema } from "../schemas/blockEquipment";

interface UseCompanyEquipmentBlockProps {
  equipmentId?: string;
  onSuccess?: () => void;
  onError?: (error: AxiosError) => void;
}

const useCompanyEquipmentBlock = ({ equipmentId, onSuccess, onError }: UseCompanyEquipmentBlockProps) => {
  const privateAxios = usePrivateAxios();
  return useMutation<void, AxiosError, EquipmentBlockValidationSchema>({
    mutationKey: ['companyEquipmentBlock', equipmentId],
    mutationFn: (data) => privateAxios.put(`/equipments/${equipmentId}/block`, {
      equipment: {
        block_reason: data.block_reason,
      },
    }),
    onSuccess,
    onError,
  });
};

export default useCompanyEquipmentBlock;
