import { privateAxios } from "@/common/api/axios";
import { defaultTransformerResponse } from "@/common/constants/defaultTransformerResponse.constant";
import { Mapper } from "@/common/services/mapper";
import { MetaResponse } from "@/common/types/metaResponse";
import { ReportsMineData } from "@/features/reports/types/reportsMine.type";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { useState } from "react";

type UseReportsMineProps<T> = {
  filters?: {
    generatorKlass?: string;
  };
  mapper?: (data: any) => T,
};

export const useReportsMine = <T,>({
  filters: { generatorKlass } = {},
  mapper = Mapper.default<T>(),
}: UseReportsMineProps<T>) => {
  const [stillComputing, setStillComputing] = useState(false);

  const {
    data: { data, meta } = defaultTransformerResponse<T>(),
    ...rest
  } = useQuery({
    queryKey: ['reportsMine', generatorKlass],
    queryFn: () => {
      return privateAxios.get(`/reports/entries/mine`, {
        params: {
          'q[generator_klass_eq]': generatorKlass,
        }
      });
    },
    refetchInterval: stillComputing ? 1000 : false,
    select: (data: { data: { data: ReportsMineData[], meta: MetaResponse; }; }) => {
      const refetch = data?.data?.data?.map((report: ReportsMineData) => report.status).includes('processing');

      refetch !== stillComputing && setStillComputing(refetch);

      return {
        data: mapper(data?.data?.data),
        meta: data?.data?.meta,
      };
    },
    placeholderData: keepPreviousData,
  });

  return { data, meta, ...rest };
};
