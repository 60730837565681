interface ChartLegendProps {
  legend: { label: string; color: string }[]
}

export const ChartLegend = ({ legend }: ChartLegendProps) => {
  return (
    <div className="flex gap-2">
      {legend.map(({ label, color }) => (
        <div key={label} className="flex items-center gap-1">
          <div className="size-2 rounded-full" style={{ backgroundColor: color }} />
          <p className="font-light text-[10px] italic">{label}</p>
        </div>
      ))}
    </div>
  )
}
