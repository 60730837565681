import Accordion from '@/common/components/Accordion'
import Box from '@/common/components/Box'
import { Checklist } from '@/common/components/Checklist'
import { CurrencyTextField } from '@/common/components/CurrencyTextField'
import { FileUploader } from '@/common/components/FileUploader'
import MaskedTextField from '@/common/components/MaskedTextField'
import Select from '@/common/components/Select'
import TextField from '@/common/components/TextField'
import { dateTransformer } from '@/common/utils/dateTransformer'
import { formatCurrency, formatDateRange } from '@/common/utils/formatters'
import { getFilenameFromUrl } from '@/common/utils/getFilenameFromUrl'
import { useMineCompany } from '@/features/company/hooks/useMineCompany'
import { GovernmentAllocationEntryData } from '@/features/governmentAllocations/types/governmentAllocationEntry.type'
import { CalendarDaysIcon, SearchIcon } from 'lucide-react'

export const GovernmentAllocationEntryCompletedForm = ({
  governmentAllocation,
}: {
  governmentAllocation: GovernmentAllocationEntryData
}) => {
  const { data: company } = useMineCompany()

  return (
    <Box className="flex gap-6 items-start h-[calc(100vh-130px)] shadow-[4px_4px_8px_4px_rgba(0,_0,_0,_0.2)]">
      <div className="w-full flex flex-col gap-6 h-[calc(100vh-178px)] overflow-y-auto">
        <p className="text-lg font-bold">
          Detalhes {governmentAllocation?.government_allocation?.kind === 'pre_paid' ? 'do Empenho' : 'da Cota'}
        </p>

        <div>
          <p className="text-sm font-light italic">Valor global do contrato</p>
          <p className="text-lg font-bold">{formatCurrency(Number(company?.current_contract?.granted_credit || 0))}</p>
        </div>

        {governmentAllocation?.government_allocation?.kind === 'pre_paid' ? (
          <div className="flex gap-x-2">
            <TextField
              label="Número do ofício do Empenho"
              className="mt-5"
              defaultValue={governmentAllocation?.office_number}
              disabled
            />

            <div className="space-y-2">
              <p className="text-[11px] leading-3">Período de competência</p>

              <div className="flex items-start gap-x-2">
                <MaskedTextField
                  label="Início"
                  mask="00/00/0000"
                  LeftIcon={<CalendarDaysIcon strokeWidth={1} />}
                  name="government_allocation_attributes.start_of_document_validity"
                  defaultValue={
                    governmentAllocation?.government_allocation?.start_of_document_validity
                      ? dateTransformer(governmentAllocation?.government_allocation?.start_of_document_validity)
                      : undefined
                  }
                  disabled
                />

                <MaskedTextField
                  label="Fim"
                  mask="00/00/0000"
                  LeftIcon={<CalendarDaysIcon strokeWidth={1} />}
                  name="government_allocation_attributes.end_of_document_validity"
                  defaultValue={
                    governmentAllocation?.government_allocation?.end_of_document_validity
                      ? dateTransformer(governmentAllocation?.government_allocation?.end_of_document_validity)
                      : undefined
                  }
                  disabled
                />
              </div>
            </div>
          </div>
        ) : (
          <>
            <div>
              <p className="text-sm font-light italic">N da cota estimativa</p>
              <p className="text-lg font-bold">{governmentAllocation?.office_number}</p>
            </div>

            <div>
              <p className="text-sm font-light italic">Período de competência</p>
              <p className="text-lg font-bold">
                {governmentAllocation?.government_allocation?.start_of_document_validity &&
                  governmentAllocation?.government_allocation?.end_of_document_validity &&
                  formatDateRange(
                    new Date(governmentAllocation?.government_allocation?.start_of_document_validity),
                    new Date(governmentAllocation?.government_allocation?.end_of_document_validity),
                  )}
              </p>
            </div>
          </>
        )}

        <div>
          <p className="text-sm font-light italic">
            Tipo de controle{' '}
            {governmentAllocation?.government_allocation?.kind === 'pre_paid' ? 'de empenho' : 'da cota'} (Não
            implementado)
          </p>
        </div>

        {governmentAllocation?.government_allocation?.kind === 'pre_paid' && (
          <FileUploader
            name="file"
            accept="application/pdf, image/png, image/jpg, image/jpeg"
            title="Anexe o Empenho"
            fileName={governmentAllocation?.file?.url ? getFilenameFromUrl(governmentAllocation?.file?.url) : undefined}
            currentFileUrl={governmentAllocation?.file?.url ? governmentAllocation?.file?.url : undefined}
            disabled
          />
        )}

        <Accordion
          className="py-1"
          head={
            <p>
              Detalhamento {governmentAllocation?.government_allocation?.kind === 'pre_paid' ? 'do Empenho' : 'da Cota'}
            </p>
          }
          body={
            <div className="flex flex-col gap-4">
              <CurrencyTextField
                label={`Valor total ${
                  governmentAllocation?.government_allocation?.kind === 'pre_paid' ? 'do empenho' : 'da cota'
                }`}
                markAsRequired
                maxValue={999_999_999.99}
                className="w-full"
                defaultValue={
                  governmentAllocation?.total_value
                    ? formatCurrency(Number(governmentAllocation?.total_value))
                    : undefined
                }
                disabled
              />

              <Select
                name="product_categories"
                placeholder="Pesquisar Serviços"
                labelIcon={<SearchIcon />}
                value={null}
                isDisabled
              />
            </div>
          }
        />
      </div>

      <Checklist
        requirement_list_summary={governmentAllocation?.requirement_list_summary}
        className="flex flex-col gap-2 justify-between h-[calc((100vh-276px)/2)]"
      />
    </Box>
  )
}
