import Button from '@/common/components/Button';
import { useFilterParams } from '@/common/hooks/useFilterParams';
import { reportFilters } from '@/features/reports/constants/reportFilters.constant';
import { XIcon } from 'lucide-react';
import { useFormContext } from 'react-hook-form';

interface ReportsFiltersFieldsProps {
  filters?: string[];
}

const ReportsFiltersFields = ({ filters }: ReportsFiltersFieldsProps) => {
  const { filterParams, setFilterParams } = useFilterParams();

  const { control, resetField } = useFormContext();

  return (
    <>
      {filters?.map((filterName) => (
        <div key={filterName} className="flex w-full items-start">
          {(reportFilters[filterName as keyof typeof reportFilters] || reportFilters.default)({
            control,
            filterName,
            value: filterParams.q?.[filterName],
            setFilterParams,
          })}
          <Button
            type="button"
            variant="outline-error"
            className="size-[52px] px-2 mt-5 bg-black text-white-800 rounded-l-none"
            onClick={() => {
              resetField(filterName);
              setFilterParams((params) => ({ ...params, q: { ...params.q, [filterName]: undefined } }));
            }}
          >
            <XIcon className="size-5" />
          </Button>
        </div>
      ))}
    </>
  );
};

export default ReportsFiltersFields;
