import { EquipmentData } from "../types/equipments.type";

export class EquipmentsTransformer {

  static optionsTransformer() {
    return (equipments: EquipmentData[]) => {
      return equipments.map((equipment) => {
        return {
          value: equipment.id,
          label: `${equipment.license_plate || equipment.official_name} ${equipment.model ? `(${equipment.model?.display_name})` : ''}`,
        };
      });
    };
  }

}
