import Box from "@/common/components/Box";
import { CopyButton } from "@/common/components/CopyButton";
import TextField from "@/common/components/TextField";
import { useCardWithSecureInformation } from "@/common/hooks/queries/useCardWithSecureInformation";
import { useCopyButton } from "@/common/hooks/useCopyButton";
import { formatCardNumber } from "@/common/utils/formatters";
import ResumeItem from "@/common/components/ResumeItem";
import { EquipmentData } from "@/features/equipments/types/equipments.type";
import { cardStatusTranslation } from "@/features/equipments/constants/cardStatusTranslation.constant";

interface EquipmentCardDetailsProps {
  equipment?: EquipmentData;
}

const EquipmentCardDetails = ({ equipment }: EquipmentCardDetailsProps) => {
  const { control, copySuccess, copyFailed } = useCopyButton();

  const {
    mutate: personalCard,
    isPending: personalCardIsPending,
  } = useCardWithSecureInformation({
    onSuccess: (response) => copySuccess(response?.data?.data?.number),
    onError: copyFailed
  });

  return (
    <Box className="flex flex-col gap-6 p-4">
      <div>
        <p>Cartão frota</p>
      </div>

      <div className="flex flex-col gap-3">
        <ResumeItem
          label="Status do cartão"
          value={equipment?.card?.status && cardStatusTranslation[equipment?.card?.status as keyof typeof cardStatusTranslation] || '--'}
        />

        <TextField
          disabled
          label="Código"
          className="grow"
          RightIcon={(!personalCardIsPending || !!equipment?.card?.id) ? <CopyButton control={control} className="text-blue-800" /> : undefined}
          onIconClick={() => personalCard(equipment?.card?.id!)}
          isLoading={personalCardIsPending}
          value={formatCardNumber(equipment?.card?.last_four_digits || '')}
        />
      </div>
    </Box>
  );
};

export default EquipmentCardDetails;
