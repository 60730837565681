import LogRequestShow from '@/common/components/LogRequestShow';
import { ShowHeader } from '@/common/components/ShowHeader';
import { useParams } from 'react-router-dom';
import useEquipment from '../../hooks/useEquipment';

const ChangeHistoryShow = () => {
  const { id: equipmentId } = useParams();

  const { data: equipment } = useEquipment({ id: equipmentId || '' });

  return (
    <div>
      <ShowHeader
        backTo={`/equipments/${equipmentId}/changeHistory`}
        logoUrl={equipment?.photos?.[0]?.url}
        title={equipment?.license_plate || equipment?.official_name || 'Não informado'}
      />

      <div className="h-[calc(100vh-64px-32px-40px)]">
        <LogRequestShow />
      </div>
    </div>
  );
};

export default ChangeHistoryShow;
