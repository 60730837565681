import { optionsObjectToArray } from "@/common/utils/optionsObjectToArray";

export const billingFiscalDocumentsStatus = {
  pending: 'Pendente',
  confirmed: 'Confirmada',
  canceled: 'Cancelada',
  invalid: 'Inválida',
  failed: 'Com Falha',
};

export const billingFiscalDocumentsStatusOptions = optionsObjectToArray(billingFiscalDocumentsStatus);
