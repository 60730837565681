import Accordion from "@/common/components/Accordion";
import Box from "@/common/components/Box";
import Button from "@/common/components/Button";
import Checkbox from "@/common/components/Checkbox";
import { MultipleDocumentUploader } from "@/common/components/MultipleDocumentUploader";
import { MultipleImageUploader } from "@/common/components/MultipleImageUploader";
import SelectController from "@/common/components/SelectController";
import useBlobs from "@/common/hooks/queries/useBlobs";
import { Mapper } from "@/common/services/mapper";
import { FormTransformers } from "@/common/utils/formTransformers";
import { zodResolver } from "@hookform/resolvers/zod";
import { useQueryClient } from "@tanstack/react-query";
import { SubmitHandler, useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import useEquipment from "../../hooks/useEquipment";
import { useCompanyBranches } from "@/common/hooks/queries/useCompanyBranches";
import { CompanyBranchData } from "@/common/types/companyBranches.type";
import { useState } from "react";
import { equipmentSchema, EquipmentValidationSchema } from "../../schemas/create.schema";
import { ShowHeader } from "@/common/components/ShowHeader";
import { PeriodRestriction } from "../../components/form/PeriodRestriction";
import { EquipmentDetails } from "../../components/form/EquipmentDetails";
import { ApplicableProducts } from "../../components/form/ApplicableProducts";
import { companyEquipmentDefaultValues } from "../../utils/companyEquipmentDefaultValues";
import useUpdateEquipment from "../../hooks/useUpdateEquipment";
import Spinner from "@/common/components/Spinner";

const EquipmentsUpdate = () => {
  const { id: equipmentId } = useParams();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { mutateAsync: uploadBlob, isPending: uploadBlobIsPending } = useBlobs();

  const { data: equipment } = useEquipment({ id: equipmentId || '' });

  const [searchCompanyBranch, setSearchCompanyBranch] = useState('');

  const {
    data: companyBranchOptions,
  } = useCompanyBranches({
    filters: { search: searchCompanyBranch || '' },
    mapper: Mapper.mapToOptions<CompanyBranchData>({ labelFieldName: 'display_name', valueFieldName: 'id' }),
  });

  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    setError,
    formState: { errors }
  } = useForm<EquipmentValidationSchema>({
    resolver: zodResolver(equipmentSchema),
    defaultValues: companyEquipmentDefaultValues({
      equipmentData: equipment,
      companyBranchOptions: companyBranchOptions || [],
    })
  });

  const { mutate: updateCompanyEquipment, isPending: updateCompanyEquipmentIsPending } = useUpdateEquipment({
    equipmentId,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['companyEquipments'] });
      navigate(`/equipments/${equipmentId}`);
    },
    onError: FormTransformers.errorsTransformer<EquipmentValidationSchema>({ setError })
  });

  const onSubmit: SubmitHandler<EquipmentValidationSchema> = async (data) => {
    updateCompanyEquipment({
      form: data,
      equipmentData: equipment,
    });
  };

  const documents = watch('documents') || [];

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ShowHeader
        backTo='/equipments'
        logoUrl={equipment?.photos?.[0]?.url}
        title={equipment?.license_plate || equipment?.official_name || 'Não informado'}
        buttons={(
          <div className="flex gap-x-4 items-center">
            <Link to={`/equipments/${equipmentId}`}>
              <Button variant="outline-error">Cancelar</Button>
            </Link>
            <Button type="submit" className="shadow-[4px_4px_8px_4px_rgba(0,_0,_0,_0.2)]" disabled={uploadBlobIsPending}>
              {updateCompanyEquipmentIsPending ? <Spinner className="h-6 mx-[23px] fill-white-800" /> : 'Salvar'}
            </Button>
          </div>
        )}
      />

      <Box className="flex flex-col gap-4 shadow-[4px_4px_8px_4px_rgba(0,_0,_0,_0.2)] overflow-y-auto max-h-[calc(100vh-128px)]">
        <p className="text-lg font-bold">Dados do equipamento</p>
        <MultipleImageUploader
          label="Anexe uma foto"
          name="equipment-images"
          accept="image/*"
          uploadBlob={uploadBlob}
          control={control}
          fieldName="photos"
          disabled={uploadBlobIsPending || updateCompanyEquipmentIsPending}
        />

        <SelectController
          control={control}
          name="branch_id"
          label="Filial"
          markAsRequired
          onInputChange={setSearchCompanyBranch}
          options={companyBranchOptions || []}
          errorMessage={errors.branch_id?.message || undefined}
          isDisabled={updateCompanyEquipmentIsPending}
        />

        <PeriodRestriction
          control={control}
          register={register}
          watch={watch}
          errors={errors}
          disabled={updateCompanyEquipmentIsPending}
        />

        <EquipmentDetails
          control={control}
          register={register}
          watch={watch}
          setValue={setValue}
          errors={errors}
          disabled={updateCompanyEquipmentIsPending}
          updateDisabled={equipment?.model?.category?.supports_infocar_data_enrichment}
        />

        <Accordion
          head={
            <Checkbox
              label="Documentos do equipamento"
              name="equipment_documents"
              boxClassName="w-[24px] h-[24px]"
              checked={documents.length > 0}
              disabled
            />
          }
          body={(
            <div className="flex w-full flex-col gap-4 text-white-800">
              <MultipleDocumentUploader
                title="Anexe um documento do equipamento"
                fieldName="documents"
                name="equipment-documents"
                accept="application/pdf"
                uploadBlob={uploadBlob}
                control={control}
                disabled={uploadBlobIsPending || updateCompanyEquipmentIsPending}
              />
            </div>
          )}
        />

        <ApplicableProducts
          control={control}
          register={register}
          watch={watch}
          errors={errors}
          disabled={updateCompanyEquipmentIsPending}
        />
      </Box>
    </form>
  );
};

export default EquipmentsUpdate;
