import Accordion from '@/common/components/Accordion';
import Box from '@/common/components/Box';
import { DocumentFileList } from '@/common/components/DocumentFileList';
import { ImageCarousel } from '@/common/components/ImageCarousel';
import TextField from '@/common/components/TextField';
import { formatDecimal, formatUnit } from '@/common/utils/formatters';
import { getFilenameFromUrl } from '@/common/utils/getFilenameFromUrl';
import { useState } from 'react';
import { EquipmentData as EquipmentDataType } from '../../../types/equipments.type';
import EditEquipmentUsageModal from '../../EditEquipmentUsageModal';
import { PeriodRestriction } from '@/features/company/components/Profile/PeriodRestriction';
import { unitTranslation } from '@/common/constants/unitStrategies.contants';
import { ApplicableProducts } from './ApplicableProducts';
import { options as propertyKindOptions } from "../../../constants/propertyKindOptions.constant";
import { options as usageKindOptions } from "../../../constants/usageKindOptions.constant";
import EquipmentCardDetails from './CardDetails';
import BlockUnblockEquipment from '../BlockUnblockEquipment';

type EquipmentDataProps = {
  equipmentId?: string;
  equipment?: EquipmentDataType;
};

export const EquipmentData = ({ equipmentId, equipment }: EquipmentDataProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <EditEquipmentUsageModal
        equipmentId={equipmentId || ''}
        setIsOpen={setIsModalOpen}
        isOpen={isModalOpen}
        initialValue={equipment?.last_usage_value}
        unitForConsumption={equipment?.model?.category?.unit_for_consumption}
      />

      <Box className="flex flex-col gap-4 shadow-[4px_4px_8px_4px_rgba(0,_0,_0,_0.2)] overflow-y-auto max-h-[calc(100vh-192px)]">
        {equipment && <BlockUnblockEquipment equipment={equipment} />}

        <p className="text-lg font-bold">
          Dados do equipamento
        </p>

        {!!equipment?.photos?.length && (
          <ImageCarousel files={equipment.photos.map((photo) => ({ id: photo.id, file: photo.url }))} />
        )}

        <EquipmentCardDetails equipment={equipment} />

        <TextField
          label="Filial"
          value={equipment?.branch?.display_name}
          disabled
        />

        {Number(equipment?.card?.restriction?.period_restrictions?.length) > 0 && (
          <PeriodRestriction periodRestrictions={equipment?.card?.restriction?.period_restrictions} />
        )}

        <p>
          Detalhes do Equipamento
        </p>

        <TextField
          label="Busque por seu modelo por Código Fipe, Marca, Nome e ano"
          value={equipment?.model?.display_name}
          disabled
        />
        <TextField label="Categoria do equipamento" value={equipment?.model?.category?.display_name} disabled />
        <div className="grid grid-cols-2 gap-x-2 gap-y-4">
          <TextField
            label="Capacidade do tanque"
            value={equipment?.tank_capacity ? formatUnit(formatDecimal(equipment?.tank_capacity), 'liter') : ''}
            disabled
          />
          <div>
            <TextField
              label={
                equipment?.model?.category?.unit_for_consumption
                  ? unitTranslation[equipment.model?.category.unit_for_consumption]
                  : ''
              }
              value={
                equipment?.last_usage_value
                  ? formatUnit(formatDecimal(equipment?.last_usage_value), equipment.model?.category.unit_for_consumption || 'kilometers')
                  : '0 Km'
              }
              disabled
            />
            <button
              className="text-[#7CC4F8] hover:text-[#5AB0F6] font-medium text-xs cursor-pointer"
              onClick={() => setIsModalOpen(true)}
            >
              Alterar última medição
            </button>
          </div>

          {equipment?.official_name && (
            <TextField
              label="Nome"
              value={equipment.official_name}
              disabled
            />
          )}

          {equipment?.serial_number && (
            <TextField
              label="Número de série"
              value={equipment.serial_number}
              disabled
            />
          )}

          {equipment?.license_plate && (
            <TextField
              label="Placa do veículo"
              value={equipment.license_plate}
              disabled
            />
          )}

          {equipment?.chassis_number && (
            <TextField
              label="Chassi"
              value={equipment.chassis_number}
              disabled
            />
          )}

          {equipment?.renavam && (
            <TextField
              label="Renavam"
              value={equipment.renavam}
              disabled
            />
          )}
        </div>

        <TextField
          label="Tipo de uso"
          value={
            equipment?.usage_kind ? usageKindOptions.find((option) => option.value === equipment.usage_kind)?.label : ''
          }
          disabled
        />

        <div className="flex gap-x-2">
          <TextField
            label="Tipo de propriedade"
            className="w-full"
            value={
              equipment?.property_kind
                ? propertyKindOptions.find((option) => option.value === equipment.property_kind)?.label
                : ''
            }
            disabled
          />

          <TextField
            label="Código proprietário"
            className="w-full"
            value={equipment?.property_code}
            disabled
          />
        </div>

        {!!equipment?.documents?.length && (
          <Accordion
            head={<p>Documentos do equipamento</p>}
            body={
              <div className="flex w-full flex-col gap-4 text-white-800">
                <DocumentFileList
                  files={equipment?.documents?.map((document) => ({
                    fileName: getFilenameFromUrl(document.url),
                    currentFileUrl: document.url,
                  }))}
                />
              </div>
            }
          />
        )}

        {!!equipment?.card?.card_product_limits?.length && (
          <ApplicableProducts applicableProducts={equipment?.card?.card_product_limits} />
        )}
      </Box>
    </>
  );
};
