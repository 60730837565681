import ListHeader from "@/common/components/ListHeader";
import Table from "@/common/components/Table";
import { DatePickerWithRange } from "@/common/components/ui/dateRangePicker";
import { useBillingBills } from "@/common/hooks/queries/useBillingBills";
import { FileText } from "lucide-react";
import billsTableColumns from "../../constants/billsTableColumns.constant";
import ListFilters from "@/common/components/ListFilters";
import { Mapper } from "@/common/services/mapper";
import { BillsTransformer } from "../../services/billsTransformer";
import { BillData } from "../../types/bills.type";
import { useFilterParams } from "@/common/hooks/useFilterParams";
import { useFilterDate } from "@/common/hooks/useFilterDate";
import { ErrorMessageWrapper } from "@/common/components/ErrorMessageWrapper";
import { billingBillStatus } from "@/common/constants/statusBills.constants";

const BillingBillsList = () => {
  const { filterParams, setFilterParams } = useFilterParams({
    status: billingBillStatus.find(({ value }) => value === 'pending_payment')
  });

  const { date, formattedDate } = useFilterDate(filterParams);

  const {
    data: bills,
    meta,
    isFetching: isFetchingBills,
    error: billsError = {} as any,
    refetch: refetchBills,
  } = useBillingBills({
    filters: {
      page: filterParams?.page || 1,
      status: filterParams?.status?.value || '',
      startDate: formattedDate.from,
      endDate: formattedDate.to,
    },
    mapper: Mapper.mapWithTransformer<BillData[], any[]>({
      transformer: BillsTransformer.tableTransformer(),
    }),
  });

  return (
    <div className="flex flex-col gap-6">
      <div className="flex">
        <ListHeader
          Icon={<FileText className="w-10 h-10" strokeWidth={1.5} />}
          title="Faturas"
        />
      </div>

      <ErrorMessageWrapper
        className="max-h-[calc(100vh-128px)]"
        statusCode={billsError?.status}
        refetchFunction={refetchBills}
      >
        <div className="h-[calc(100vh-128px)] flex flex-col gap-6">
          <div className="flex flex-col gap-4">
            <div className="flex items-center gap-4">
              <DatePickerWithRange
                date={date}
                setDate={(date) => setFilterParams((params) => ({ ...params, date }))}
              />

              <ListFilters
                filters={[
                  {
                    className: "",
                    onChange: (e: any) => setFilterParams((params) => ({...params, status: e })),
                    defaultValue: filterParams?.status,
                    options: [{ label: "Todas", value: '' }, ...(billingBillStatus || [])],
                    placeholder: "Status",
                  },
                ]}
              />
            </div>
          </div>

          <Table
            className="max-h-screen"
            columns={billsTableColumns}
            isLoadingData={isFetchingBills}
            rows={bills}
            rowEmptyValue="--"
            page={filterParams.page || 1}
            setPage={(p) => setFilterParams((params) => ({ ...params, page: p }))}
            totalPages={meta.total_pages}
            totalCount={meta.total}
          />
        </div>
      </ErrorMessageWrapper>
    </div>
  );
};

export default BillingBillsList;
