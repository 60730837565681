import { EquipmentCategoryData, RequiredFieldName } from '@/features/equipments/types/equipments.type'

export class CompanyEquipmentCategoriesTransformer {
  static optionTransformer(equipmentCategories: EquipmentCategoryData[] = []) {
    return equipmentCategories.map((equipmentCategory) => {
      const requiredFieldNames = [] as { requiredFieldName: RequiredFieldName }[]

      if (equipmentCategory?.supports_infocar_data_enrichment) {
        requiredFieldNames.push({ requiredFieldName: RequiredFieldName.InfocarFields })
      } else {
        requiredFieldNames.push({ requiredFieldName: RequiredFieldName.TankCapacity })
      }

      equipmentCategory?.required_field_names?.forEach((requiredFieldName) => {
        if (equipmentCategory?.supports_infocar_data_enrichment && requiredFieldName === RequiredFieldName.LicensePlate)
          return
        requiredFieldNames.push({ requiredFieldName })
      })

      return {
        label: equipmentCategory.display_name,
        value: equipmentCategory.id,
        requiredFieldNames,
        supportsInfocarDataEnrichment: equipmentCategory.supports_infocar_data_enrichment,
      }
    })
  }
}
