import Box from "@/common/components/Box";
import Button from "@/common/components/Button";
import { ShowHeader } from "@/common/components/ShowHeader";
import { useCompanyBranch } from "@/common/hooks/queries/useCompanyBranch";
import { useNavigate, useParams } from "react-router-dom";
import { SubmitHandler, useForm } from "react-hook-form";
import { companyBranchSchema, CompanyBranchValidationSchema } from "../../schemas/companyBranch.schema";
import Spinner from "@/common/components/Spinner";
import { zodResolver } from "@hookform/resolvers/zod";
import { companyBranchDefaultValues } from "../../utils/companyBranchDefaultValues";
import { useCompanyBranches } from "@/common/hooks/queries/useCompanyBranches";
import { CompanyBranchData } from "@/features/company/types/company.type";
import { Mapper } from "@/common/services/mapper";
import useUpdateCompanyBranch from "../../hooks/useUpdateCompanyBranch";
import { BranchInformation } from "../../components/form/BranchInformation";
import { GeneralInformation } from "../../components/form/GeneralInformation";
import { MasterClientUser } from "../../components/form/MasterClientUser";
import { CreditConfiguration } from "../../components/form/CreditConfiguration";
import { ConsumptionRestriction } from "../../components/form/ConsumptionRestriction";
import { PeriodRestriction } from "../../components/form/PeriodRestriction";
import { useMineCompany } from "@/features/company/hooks/useMineCompany";
import { useEffect, useState } from "react";
import { ImageWithPlaceholder } from "@/common/components/ImageWithPlaceholder";
import { BriefcaseBusinessIcon } from "lucide-react";
import { FormTransformers } from "@/common/utils/formTransformers";
import { ModalMessageSimple } from "@/common/components/ModalMessageSimple";
import { CompanyBranchesTransformer } from "../../services/companyBranchesTransformer";

const CompanyBranchesUpdate = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [messages, setMessages] = useState<string[] | boolean>(false);
  const [file, setFile] = useState<File>();

  const { data: companyData } = useMineCompany();

  const { data: branchData, isFetched: branchDataIsFetched } = useCompanyBranch(id || '');

  const { data: companyBranchOptions } = useCompanyBranches({
    filters: { pageSize: 1000 },
    mapper: Mapper.mapToOptions<CompanyBranchData>({ labelFieldName: 'display_name', valueFieldName: 'id' }),
  });

  const { mutate: updateCompanyBranch, isPending } = useUpdateCompanyBranch({
    branchId: id,
    branchData,
    onSuccess: () => {
      navigate(`/company-branches/${id}`);
    },
    onError: (error) => {
      FormTransformers.errorsTransformer({
        setError,
        setMessages,
        transformer: CompanyBranchesTransformer.errorsTransformer()
      })(error);
    },
  });

  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    setError,
    reset,
    formState: { errors }
  } = useForm<CompanyBranchValidationSchema>({
    resolver: zodResolver(companyBranchSchema),
    defaultValues: companyBranchDefaultValues({ branchData, companyBranchOptions }),
  });

  const onSubmit: SubmitHandler<CompanyBranchValidationSchema> = (data) => {
    updateCompanyBranch({ form: data, file });
  };

  useEffect(() => {
    if (branchDataIsFetched) {
      reset(companyBranchDefaultValues({ branchData, companyBranchOptions }));
    }
  }, [branchDataIsFetched]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ShowHeader
          backTo="/company-branches"
          LogoPlaceholder={
            <ImageWithPlaceholder
              className="size-8"
              iconClassName="size-6"
              placeholderIcon={BriefcaseBusinessIcon}
              url={branchData?.general_information?.company_logo?.url}
              alt={branchData?.general_information?.fantasy_name}
            />
          }
          title={branchData?.display_name}
          buttons={(
            <Button
              type="submit"
              className="shadow-[4px_4px_8px_4px_rgba(0,_0,_0,_0.2)]" >
              {isPending ? (
                <Spinner className="h-6 mx-[23px] fill-white-800" />
              ) : (
                'Confirmar'
              )}
            </Button>
          )}
        />

        <Box className="flex flex-col gap-4 shadow-[4px_4px_8px_4px_rgba(0,_0,_0,_0.2)] overflow-y-auto h-[calc(100vh-128px)]">
          <p className="text-lg font-bold">
            Dados da filial
          </p>

          <BranchInformation
            register={register}
            errors={errors}
            control={control}
            showParentIdField={!!branchData?.parent_id}
          />

          <GeneralInformation
            companyData={companyData}
            branchData={branchData}
            register={register}
            errors={errors}
            control={control}
            watch={watch}
            setValue={setValue}
            setError={setError}
            file={file}
            setFile={setFile}
            companyLogo={branchData?.general_information?.company_logo}
            disableCnpj={!branchData?.parent_id}
          />

          <MasterClientUser
            errors={errors}
            control={control}
          />

          <CreditConfiguration
            companyData={companyData}
            register={register}
            control={control}
            watch={watch}
            errors={errors}
          />

          <ConsumptionRestriction
            restriction={branchData?.restriction}
            register={register}
            errors={errors}
            disabled={isPending}
          />

          <PeriodRestriction
            control={control}
            register={register}
            watch={watch}
            errors={errors}
            disabled={isPending}
          />
        </Box>
      </form>

      {messages && (
        <ModalMessageSimple
          messages={messages as string[]}
          setMessages={setMessages}
          isError
        />
      )}
    </>

  );
};

export default CompanyBranchesUpdate;
