import usePrivateAxios from '@/common/api/usePrivateAxios'
import {
  GovernmentAllocationData,
  GovernmentAllocationResponse,
} from '@/features/governmentAllocations/types/governmentAllocation.type'
import { UseQueryResult, useQuery } from '@tanstack/react-query'

export const useGovernmentAllocation: (id: string) => UseQueryResult<GovernmentAllocationData, unknown> = (id) => {
  const privateAxios = usePrivateAxios()

  return useQuery<GovernmentAllocationResponse, unknown, GovernmentAllocationData>({
    queryKey: ['companyGovernmentAllocation', id],
    queryFn: () => privateAxios.get(`/billing/government_allocations/${id}`),
    select: (data) => {
      return data?.data?.data
    },
  })
}
