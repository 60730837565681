import { useMutation } from "@tanstack/react-query";
import usePrivateAxios from "@/common/api/usePrivateAxios";
import { AxiosError } from "axios";

interface UseCompanyEquipmentBlockProps {
  equipmentId?: string;
  onSuccess?: () => void;
  onError?: (error: AxiosError) => void;
}

const useCompanyEquipmentUnblock = ({ equipmentId, onSuccess, onError }: UseCompanyEquipmentBlockProps) => {
  const privateAxios = usePrivateAxios();
  return useMutation<void, AxiosError>({
    mutationKey: ['companyEquipmentUnblock', equipmentId],
    mutationFn: () => privateAxios.put(`/equipments/${equipmentId}/unblock`),
    onSuccess,
    onError,
  });
};

export default useCompanyEquipmentUnblock;
