import { reportFileTypes } from "../constants/reportFileTypesOptions.constant";

export class ReportTypesTransformer {
  static optionsTransformer() {
    return (types: string[]) => {
      return types.map((type) => {
        return {
          value: type,
          label: reportFileTypes[type as keyof typeof reportFileTypes] || type,
        };
      });
    };
  }
}
