import { formatDate } from "@/common/utils/formatters";
import { DownloadIcon } from "lucide-react";
import { Link } from "react-router-dom";
import { FiscalDocumentData } from "../types/fiscalDocument.type";
import { billingFiscalDocumentsStatus } from "../constants/billFiscalDocumentsStatus.constant";

export class BillFiscalDocumentsTransformer {
  static tableTransformer() {
    return (fiscalDocuments: FiscalDocumentData[]) => {
      return fiscalDocuments.map((fiscalDocument) => {
        const link = fiscalDocument.link || fiscalDocument.pdf?.url || fiscalDocument.xml?.url;

        return {
          ...fiscalDocument,
          status: fiscalDocument?.status ? billingFiscalDocumentsStatus[fiscalDocument?.status as keyof typeof billingFiscalDocumentsStatus] : '',
          issued_at: fiscalDocument.issued_at ? formatDate(new Date(fiscalDocument.issued_at)) : '',
          actions: link ? <Link to={link} target="_blank"><DownloadIcon /></Link> : '',
        };
      });
    };
  }
}
