import { useGoBack } from "@/common/hooks/useGoBack";
import { ChevronLeftIcon, ChevronsRight } from "lucide-react";
import { Link } from "react-router-dom";
import Button from "../Button";
import { twMerge } from "tailwind-merge";
import { cn } from "@/common/utils/cn";

interface ShowHeaderProps {
  className?: string;
  backTo?: string;
  goBack?: boolean;
  title?: string;
  titleClassName?: string;
  subtitle?: string;
  subtitleClassName?: string;
  logoClassName?: string;
  logoUrl?: string;
  LogoPlaceholder?: JSX.Element;
  badge?: JSX.Element;
  buttons?: JSX.Element;
}

export const ShowHeader = ({
  className,
  backTo,
  goBack,
  title,
  titleClassName,
  subtitle,
  subtitleClassName,
  logoClassName,
  logoUrl,
  LogoPlaceholder,
  badge,
  buttons,
}: ShowHeaderProps) => {
  const _goBack = useGoBack();

  return (
    <div className={cn('flex justify-between items-center mb-6', className)}>
      <div className="flex gap-2 items-center">
        {goBack && (
          <Button variant="link-white" onClick={() => _goBack({ fallback: backTo })}>
            <ChevronLeftIcon size={40} strokeWidth={1} />
          </Button>
        )}

        {!goBack && backTo && (
          <Link to={backTo} className="cursor-pointer">
            <ChevronLeftIcon size={40} strokeWidth={1} />
          </Link>
        )}

        {logoUrl ? (
          <img
            src={logoUrl}
            alt={title}
            className={twMerge('size-8 rounded-full object-cover', logoClassName)}
          />
        ) : LogoPlaceholder ? (
          <>
            {LogoPlaceholder}
          </>
        ) : (
          <></>
        )}

        {title && (
          <>
            <h2 className={twMerge('text-2xl font-bold', titleClassName)}>
              {title}
            </h2>

            {subtitle && (
              <>
                <ChevronsRight className="text-white-400" />

                <h2 className={subtitleClassName}>
                  {subtitle}
                </h2>
              </>
            )}

            {badge}
          </>
        )}
      </div>

      {buttons}
    </div>
  );
};
