import Spinner from '@/common/components/Spinner';
import { ReportsMineData } from '@/features/reports/types/reportsMine.type';
import { DownloadIcon, XIcon } from 'lucide-react';
import { Tooltip } from 'react-tooltip';

export const actionButtonComponents = (report: ReportsMineData) => ({
  default: '--',
  processing: <Spinner className="size-6 fill-white-800" />,
  completed: (
    <a href={report?.file?.url} download={report?.title} target="_blank">
      <DownloadIcon strokeWidth={1} />
    </a>
  ),
  failed: (
    <>
      <XIcon data-tooltip-id={report.id} className="text-red-500" strokeWidth={1} />
      <Tooltip id={report.id}>Falha ao gerar relatório</Tooltip>
    </>
  ),
});
