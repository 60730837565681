import { GovernmentAllocationEntryAwaitingManagementReviewForm } from '@/features/governmentAllocations/pages/Update/components/governmentAllocationEntryFormStates/GovernmentAllocationEntryAwaitingManagementReviewForm'
import { GovernmentAllocationEntryCompletedForm } from '@/features/governmentAllocations/pages/Update/components/governmentAllocationEntryFormStates/GovernmentAllocationEntryCompletedForm'
import { GovernmentAllocationEntryCreatedForm } from '@/features/governmentAllocations/pages/Update/components/governmentAllocationEntryFormStates/GovernmentAllocationEntryCreatedForm'

export const governmentAllocationFormStateOptions = [
  {
    value: 'created',
    comp: GovernmentAllocationEntryCreatedForm,
  },
  {
    value: 'awaiting_management_review',
    comp: GovernmentAllocationEntryAwaitingManagementReviewForm,
  },
  {
    value: 'completed',
    comp: GovernmentAllocationEntryCompletedForm,
  },
]
