import { publicDomainsAxios } from "@/common/api/axios";
import { PublicDomainData } from "@/common/types/publicDomain";
import { useMutation } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";

interface UsePublicDomainProps {
  onSuccess: (response: AxiosResponse<{ data: PublicDomainData; }>) => void;
  onError: (error: AxiosError) => void;
}

const usePublicDomain = ({ onSuccess, onError }: UsePublicDomainProps) => {
  return useMutation({
    mutationKey: ['publicDomainsByType'],
    retry: false,
    mutationFn: (companyDomain: string | null) => {
      return publicDomainsAxios.get(`/domains/by_value`, {
        params: {
          value: companyDomain,
          domainable_type: 'Client::Company',
        },
      });
    },
    onSuccess,
    onError,
  });
};

export default usePublicDomain;
