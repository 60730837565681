import EventsTab from "../components/EventsTab";
import DocumentsTab from "../components/DocumentsTab";
import ResumeTab from "../components/ResumeTab";

export const billingBillTabs = [
  {
    label: 'Resumo',
    value: '',
    body: <ResumeTab />,
  },
  {
    label: 'Nota fiscal / Cobranças',
    value: 'documents',
    body: <DocumentsTab />,
  },
  {
    label: 'Transações',
    value: 'events',
    body: <EventsTab />,
  },
];
