import Button from '@/common/components/Button'
import { CurrencyTextField } from '@/common/components/CurrencyTextField'
import { FileUploader } from '@/common/components/FileUploader'
import Modal from '@/common/components/Modal'
import { ModalMessageSimple } from '@/common/components/ModalMessageSimple'
import TextField from '@/common/components/TextField'
import { FormTransformers } from '@/common/utils/formTransformers'
import { useCreateGovernmentAllocationEntry } from '@/features/governmentAllocations/hooks/useCreateGovernmentAllocationEntry'
import {
  createGovernmentAllocationEntrySchema,
  CreateGovernmentAllocationValidationEntrySchema,
} from '@/features/governmentAllocations/schemas/createGovernmentAllocationEntry.schema'
import { zodResolver } from '@hookform/resolvers/zod'
import { useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'

export const CreateGovernmentAllocationEntryModal = ({
  governmentAllocationId,
  disabled,
}: {
  governmentAllocationId: string
  disabled: boolean
}) => {
  const queryClient = useQueryClient()

  const [isOpen, setIsOpen] = useState(false)
  const [messages, setMessages] = useState<string[] | boolean>(false)

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    setError,
    clearErrors,
    reset: resetForm,
    formState: { errors },
  } = useForm<CreateGovernmentAllocationValidationEntrySchema>({
    resolver: zodResolver(createGovernmentAllocationEntrySchema),
  })

  const onSubmit: SubmitHandler<CreateGovernmentAllocationValidationEntrySchema> = (form) => {
    createGovernmentAllocation({ governmentAllocationId, form, kind: 'reinforcement' })
  }

  const onSuccess = () => {
    resetForm()
    setValue('total_value', '')
    queryClient.invalidateQueries({ queryKey: ['companyGovernmentAllocationEntries', governmentAllocationId] })
    setIsOpen(false)
  }

  const onError = (error: any) => {
    if (error.response?.data.errors.base) {
      setMessages(error.response?.data.errors.base)
    } else {
      FormTransformers.errorsTransformer({ setError })(error)
    }
  }

  const {
    mutate: createGovernmentAllocation,
    isPending: createGovernmentAllocationIsPending,
    isError: createGovernmentAllocationIsError,
  } = useCreateGovernmentAllocationEntry({
    onSuccess,
    onError,
  })

  const file = getValues('file')
  const fileName = getValues('file_name')

  return (
    <>
      <Modal
        isOpen={isOpen && !messages}
        setIsOpen={setIsOpen}
        onConfirm={handleSubmit(onSubmit)}
        Trigger={<Button disabled={disabled}>Reforço de empenho</Button>}
        triggerDisabled={disabled}
        title="Cadastrando reforço"
        footer="Cadastrar reforço"
        body={
          <form className="flex flex-col gap-4 text-white-800">
            <TextField
              label="Número do ofício do empenho de reforço"
              markAsRequired
              maxLength={24}
              errorMessage={errors?.office_number?.message}
              {...register('office_number')}
              disabled={createGovernmentAllocationIsPending}
            />

            <CurrencyTextField
              label="Valor total do empenho de reforço"
              markAsRequired
              maxValue={1_000_000_000}
              errorMessage={errors.total_value?.message}
              {...register('total_value')}
              disabled={createGovernmentAllocationIsPending}
            />

            <div>
              <FileUploader
                name="file"
                accept="application/pdf, image/png, image/jpg, image/jpeg"
                title="Anexe o Empenho de reforço"
                className="w-full bg-yellow-op-2 border-none"
                fileName={fileName || undefined}
                currentFileUrl={file ? URL.createObjectURL(file) : undefined}
                handleChange={(file) => {
                  clearErrors('file')
                  setValue('file', file)
                  setValue('file_name', file.name)
                }}
                disabled={createGovernmentAllocationIsPending}
              />
              {errors.file?.message && <span className="text-danger-soft text-sm">{errors.file?.message}</span>}
            </div>
          </form>
        }
      />
      {messages && (
        <ModalMessageSimple messages={messages} setMessages={setMessages} isError={createGovernmentAllocationIsError} />
      )}
    </>
  )
}
