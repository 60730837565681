import Box from '@/common/components/Box';
import { ShowHeader } from '@/common/components/ShowHeader';
import { useMineCompany } from '@/features/company/hooks/useMineCompany';
import { useMineContract } from '../../hooks/useMineContract';
import { Link } from 'react-router-dom';
import { ChevronLeft } from 'lucide-react';
import { BillDetails } from '../../components/Contract/BillDetails';
import { EquipmentsDetails } from '../../components/Contract/EquipmentsDetails';
import { FeesDetails } from '../../components/Contract/FeeDetails';
import { FileUploader } from '@/common/components/FileUploader';
import { getFilenameFromUrl } from '@/common/utils/getFilenameFromUrl';
import { ErrorMessageWrapper } from '@/common/components/ErrorMessageWrapper';

const CompanyContract = () => {
  const { data: company } = useMineCompany();
  const {
    data: contract,
    error: contractError = {} as any,
    refetch: refetchContract,
  } = useMineContract();

  return (
    <div>
      <ShowHeader
        logoUrl={company?.matrix_branch?.general_information.company_logo?.url}
        title={company?.matrix_branch?.general_information.fantasy_name}
        backTo="/company"
      />

      <Box className="flex flex-col gap-6 h-[calc(100vh-128px)] overflow-y-auto">
        <div className="flex items-center gap-2">
          <Link to="/company/mine">
            <ChevronLeft size={32} strokeWidth={1} />
          </Link>

          <p className="text-[18px] font-bold">
            Contrato
          </p>
        </div>

        <ErrorMessageWrapper
          className="max-h-[calc(100vh-128px)]"
          statusCode={contractError?.status}
          refetchFunction={refetchContract}
        >
          <FileUploader
            label=""
            title="Contrato assinado"
            name="document"
            accept="application/pdf"
            fileName={contract?.file?.url ? getFilenameFromUrl(contract.file.url) : undefined}
            currentFileUrl={contract?.file?.url ? contract.file.url : undefined}
            disabled
          />

          <BillDetails contract={contract} />

          <EquipmentsDetails equipments={contract?.contract_equipment_categories} />

          <FeesDetails fees={contract?.contract_fees} />
        </ErrorMessageWrapper>
      </Box>
    </div>
  );
};

export default CompanyContract;
