import { optionsToStrategy } from '@/common/utils/optionsToStrategy'

export const governmentAllocationEntriesKind = {
  original: 'Original',
  reinforcement: 'Reforço de empenho',
  annulative: 'Anulação de saldo',
}

export const governmentAllocationEntriesKindOptions = Object.entries(governmentAllocationEntriesKind).map(
  ([value, label]) => ({ value, label }),
)

export const fiscalDocumentKindStrategy = optionsToStrategy(governmentAllocationEntriesKindOptions)
