import { ChartData } from '@/common/types/chart.type'

export class ChartDataTransformer {
  static chartTransformer() {
    return (charts: ChartData[] = []) => {
      return charts.map((chartData) => {
        const seriesKeys = Object.keys(chartData.series_settings)

        const parsedData = chartData.data.map((item) => ({
          ...item,
          ...Object.fromEntries(seriesKeys.map((key) => [key, Number(item[key])])),
        }))

        return { ...chartData, data: parsedData }
      })
    }
  }
}
