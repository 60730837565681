import Button from '@/common/components/Button';
import { ModalMessageSimple } from '@/common/components/ModalMessageSimple';
import { ShowHeader } from '@/common/components/ShowHeader';
import { useFilterParams } from '@/common/hooks/useFilterParams';
import { usePermittedReports } from '@/features/reports/hooks/usePermittedReports';
import { PermittedReportsData } from '@/features/reports/types/permittedReports.type';
import { useQueryClient } from '@tanstack/react-query';
import { FileSpreadsheetIcon } from 'lucide-react';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import ReportsMineTable from '../../components/MineTable';
import ReportsFiltersFields from '../../components/ReportsFiltersFields';
import { useReportSubmit } from '../../hooks/useReportSubmit';
import SelectController from '@/common/components/SelectController';
import { reportFileTypesOptions } from '../../constants/reportFileTypesOptions.constant';
import { Option } from '@/common/types/option';
import { ReportTypesTransformer } from '../../services/reportTypesTransformer';

const ReportsSubmit = () => {
  const { filterParams } = useFilterParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [reportModel, setReportModel] = useState<PermittedReportsData>();
  const [messages, setMessages] = useState<string[] | boolean>(false);

  const { data: permittedReports, isPending } = usePermittedReports<PermittedReportsData[]>({});

  const {
    mutate: reportSubmit,
    isPending: reportSubmitIsPending,
    isError: reportSubmitIsError,
  } = useReportSubmit({
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['reportsMine', reportModel?.generator_klass] }),
    onError: (error) => {
      setMessages(error?.response?.data?.errors?.base || false);
    },
  });

  const useFormMethods = useForm();

  const { handleSubmit, control } = useFormMethods;

  const onSubmit = ({ report_type }: { report_type?: Option; }) => {
    reportSubmit({
      filters: filterParams,
      report_type: report_type?.value || 'csv'
    });
  };

  const returnToReports = () => navigate('/reports');

  useEffect(() => {
    if (!isPending) {
      if (!filterParams.generator_klass) {
        return returnToReports();
      }

      const report = permittedReports?.find((report) => report.generator_klass === filterParams.generator_klass);

      if (!report) {
        return returnToReports();
      }

      setReportModel(report);
    }
  }, [filterParams.generator_klass, isPending]);

  return (
    <div className="flex flex-col gap-6">
      <ShowHeader
        backTo="/reports"
        LogoPlaceholder={<FileSpreadsheetIcon className="w-10 h-10" strokeWidth={1} />}
        title="Relatórios"
        subtitle={reportModel?.title}
      />

      <div className="flex gap-3">
        <FormProvider {...useFormMethods}>
          <form className="w-1/2 flex flex-col gap-4" onSubmit={handleSubmit(onSubmit)}>
            <ReportsFiltersFields filters={reportModel?.filters} />

            <SelectController
              control={control}
              name="report_type"
              label="Tipo do arquivo"
              options={ReportTypesTransformer.optionsTransformer()(reportModel?.report_types || [])}
              defaultValue={reportFileTypesOptions.find(({ value }) => value === 'csv')}
            />

            <Button type="submit" isLoading={reportSubmitIsPending}>
              Criar relatório
            </Button>
          </form>
        </FormProvider>

        <div className="w-1/2">
          <ReportsMineTable reportModel={reportModel} />
        </div>
      </div>

      {messages && <ModalMessageSimple messages={messages} setMessages={setMessages} isError={reportSubmitIsError} />}
    </div>
  );
};

export default ReportsSubmit;
