import AddressForm from '@/common/components/AddressForm';
import Box from '@/common/components/Box';
import { ShowHeader } from '@/common/components/ShowHeader';
import { AddressData } from '@/common/types/address';
import { useMineCompany } from '@/features/company/hooks/useMineCompany';
import { GeneralInformationSection } from '../../components/Profile/GeneralInformationSection';
import { OperationalInformationSection } from '../../components/Profile/OperationalInformationSection';
import { MasterClientUser } from '../../components/Profile/MasterClientUser';
import { ErrorMessageWrapper } from '@/common/components/ErrorMessageWrapper';

const CompanyProfile = () => {
  const {
    data: company,
    isPending: companyIsPending,
    error: companyError = {} as any,
    refetch: refetchCompany,
  } = useMineCompany();

  return (
    <div>
      <ShowHeader
        logoUrl={company?.matrix_branch?.general_information.company_logo?.url}
        title={company?.matrix_branch?.general_information.fantasy_name}
        backTo="/company"
      />

      <ErrorMessageWrapper
        className='max-h-[calc(100vh-128px)]'
        statusCode={companyError?.status}
        refetchFunction={refetchCompany}
      >
        <Box className="flex flex-col gap-6 h-[calc(100vh-128px)] overflow-y-auto">
          <OperationalInformationSection
            currentContract={company?.current_contract}
            matrixBranch={company?.matrix_branch}
          />

          <GeneralInformationSection
            kind={company?.kind}
            generalInformation={company?.matrix_branch?.general_information}
          />

          <div className="flex flex-col gap-4">
            <p className="text-[18px] font-bold">
              Endereço
            </p>

            {!companyIsPending && (
              <AddressForm
                initialAddress={(company?.matrix_branch?.general_information?.address || {}) as AddressData}
                errors={{}}
                disableFields
              />
            )}
          </div>

          <MasterClientUser
            masterClientUser={company?.matrix_branch.master_client_user}
          />
        </Box>
      </ErrorMessageWrapper>
    </div>
  );
};

export default CompanyProfile;
