import usePrivateAxios from '@/common/api/usePrivateAxios'
import { unFormatCurrency } from '@/common/utils/unFormatters'
import { CreateGovernmentAllocationAnnulativeEntryValidationSchema } from '@/features/governmentAllocations/schemas/createGovernmentAllocationAnnulativeEntry.schema'
import { CreateGovernmentAllocationValidationEntrySchema } from '@/features/governmentAllocations/schemas/createGovernmentAllocationEntry.schema'
import { useMutation } from '@tanstack/react-query'

type CreateGovernmentAllocationEntryProps = {
  onSuccess: () => void
  onError: (error: any) => void
}

export const useCreateGovernmentAllocationEntry = ({ onSuccess, onError }: CreateGovernmentAllocationEntryProps) => {
  const privateAxios = usePrivateAxios()

  return useMutation({
    mutationKey: ['createGovernmentAllocationEntry'],
    mutationFn: async ({
      governmentAllocationId,
      form,
      kind,
      totalValue,
    }: {
      governmentAllocationId: string
      form: CreateGovernmentAllocationValidationEntrySchema | CreateGovernmentAllocationAnnulativeEntryValidationSchema
      kind: string
      totalValue?: number
    }) => {
      const total_value = 'total_value' in form ? unFormatCurrency(form.total_value) : totalValue

      const government_allocation_entry = {
        government_allocation_id: governmentAllocationId,
        office_number: form.office_number,
        total_value,
        file: form.file,
        kind,
      }

      return privateAxios.postForm('/billing/government_allocation_entries', {
        government_allocation_entry,
      })
    },
    onSuccess: onSuccess,
    onError: onError,
  })
}
