import { optionsToStrategy } from '@/common/utils/optionsToStrategy'

export enum GovernmentAllocationEntryStatus {
  created = 'created',
  awaiting_management_review = 'awaiting_management_review',
  completed = 'completed',
}

export const governmentAllocationEntryStatusOptions = [
  { value: GovernmentAllocationEntryStatus.created, label: 'Criado' },
  { value: GovernmentAllocationEntryStatus.awaiting_management_review, label: 'Aguardando revisão' },
  { value: GovernmentAllocationEntryStatus.completed, label: 'Concluído' },
]

export const governmentAllocationEntryStatusStrategy = optionsToStrategy(governmentAllocationEntryStatusOptions)
