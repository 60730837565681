import Box from "@/common/components/Box";
import TextField from "@/common/components/TextField";
import { zodResolver } from "@hookform/resolvers/zod";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { companyUrlSchema, CompanyUrlValidationSchema } from "../../schemas/companyDomain.schema";
import { getCompanyDomain, setCompanyDomain } from "@/common/services/companyDomainGetSet";
import { useEffect } from "react";
import usePublicDomain from "@/common/hooks/queries/usePublicDomain";

const companyDomain = () => {
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    reset,
    setError,
    formState: { errors }
  } = useForm<CompanyUrlValidationSchema>({
    resolver: zodResolver(companyUrlSchema)
  });

  const { mutate: publicDomain } = usePublicDomain({
    onSuccess(response) {
      reset();
      navigate('/login');
      setCompanyDomain(response.data.data.value);
    },
    onError() {
      setError('company_domain', { message: 'Domínio não encontrado' });
    }
  });

  const onSubmit: SubmitHandler<CompanyUrlValidationSchema> = (data) => publicDomain(data.company_domain);

  useEffect(() => {
    if (getCompanyDomain()) {
      navigate('/login');
    }
  }, []);

  return (
    <div className="flex flex-col items-center gap-8 w-screen">
      <img src="/Logo.svg" />

      <form onSubmit={handleSubmit(onSubmit)}>
        <Box className="flex flex-col gap-4 rounded-lg rounded-b-none backdrop-blur-lg w-[460px]">
          <div className="flex flex-col gap-2 items-center text-center">
            <h3 className="text-2xl font-bold">
              Bem vindo!
            </h3>

            <p className="max-w-[260px] font-light">
              Confirme a url da sua empresa para acessar a plataforma.
            </p>
          </div>

          <TextField
            label="Url da sua empresa"
            variant={errors.company_domain?.message ? 'error' : 'default'}
            errorMessage={errors.company_domain?.message || ' '}
            {...register('company_domain')}
          />

          <div className="flex justify-end hidden">
            <button
              type="button"
              className="text-danger-soft hover:text-danger text-sm bg-transparent"
              onClick={() => navigate('/recover-company-domain')}
            >
              Esqueci a url da empresa
            </button>
          </div>
        </Box>

        <button type="submit" className="w-full bg-white-800 text-black rounded-b-lg rounded-t-md py-4">
          {false ? 'Carregando...' : 'Entrar na conta'}
        </button>
      </form>

      <div className="flex flex-col items-center">
        <span className="text-[10px] italic font-light">
          Desenvolvido por
        </span>

        <img src='/Avantsoft.svg' />
      </div>
    </div>
  );
};

export default companyDomain;
