import { privateAxios } from '@/common/api/axios';
import { defaultTransformerResponse } from '@/common/constants/defaultTransformerResponse.constant';
import { Mapper } from '@/common/services/mapper';
import { MetaResponse } from '@/common/types/metaResponse';
import { keepPreviousData, useQuery } from '@tanstack/react-query';

type UseGovernmentAllocationsDistributionsProps<T> = {
  governmentAllocationId?: string;
  filters?: {
    search?: string;
    page?: number;
  };
  mapper?: (data: any) => T;
};

export const useGovernmentAllocationsDistributions = <T,>({
  governmentAllocationId,
  filters: { search = '', page = 1 } = {},
  mapper = Mapper.default<T>(),
}: UseGovernmentAllocationsDistributionsProps<T>) => {
  const { data: { data, meta } = defaultTransformerResponse<T>(), ...rest } = useQuery({
    queryKey: ['governmentAllocationsDistributions', governmentAllocationId, search, page],
    queryFn: () => {
      return privateAxios.get(`/billing/government_allocations/${governmentAllocationId}/distributions`, {
        params: {
          search,
          page,
        },
      });
    },
    select: (data: { data: { data: any[]; meta: MetaResponse; }; }) => {
      return {
        data: mapper(data?.data?.data),
        meta: data?.data?.meta,
      };
    },
    placeholderData: keepPreviousData,
  });

  return { data, meta, ...rest };
};
