import Box from "@/common/components/Box";
import Button from "@/common/components/Button";
import SelectController from "@/common/components/SelectController";
import Spinner from "@/common/components/Spinner";
import { Mapper } from "@/common/services/mapper";
import { zodResolver } from "@hookform/resolvers/zod";
import { ChevronLeft, ChevronsRight } from "lucide-react";
import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import useCreateEquipment from "../../hooks/useCreateEquipment";
import { MultipleImageUploader } from "@/common/components/MultipleImageUploader";
import { PeriodRestriction } from "../../components/form/PeriodRestriction";
import Checkbox from "@/common/components/Checkbox";
import { MultipleDocumentUploader } from "@/common/components/MultipleDocumentUploader";
import Accordion from "@/common/components/Accordion";
import useBlobs from "@/common/hooks/queries/useBlobs";
import { equipmentSchema, EquipmentValidationSchema } from "../../schemas/create.schema";
import { CompanyBranchData } from "@/common/types/companyBranches.type";
import { useCompanyBranches } from "@/common/hooks/queries/useCompanyBranches";
import { useQueryClient } from "@tanstack/react-query";
import { FormTransformers } from "@/common/utils/formTransformers";
import { EquipmentDetails } from "../../components/form/EquipmentDetails";
import { ApplicableProducts } from "../../components/form/ApplicableProducts";
import { companyBranchDefaultPeriodRestrictionData } from "../../utils/companyBranchDefaultPeriodRestrictionData";

const EquipmentsCreate = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const [searchCompanyBranch, setSearchCompanyBranch] = useState('');

  const {
    data: companyBranchOptions,
  } = useCompanyBranches({
    filters: { search: searchCompanyBranch || '' },
    mapper: Mapper.mapToOptions<CompanyBranchData>({ labelFieldName: 'display_name', valueFieldName: 'id' }),
  });

  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    setError,
    formState: { errors }
  } = useForm<EquipmentValidationSchema>({
    resolver: zodResolver(equipmentSchema),
    defaultValues: {
      period_restrictions_attributes: companyBranchDefaultPeriodRestrictionData(),
    },
  });

  const { mutateAsync: uploadBlob, isPending: uploadBlobIsPending } = useBlobs();
  const { mutate: createEquipment, isPending: createEquipmentIsPending } = useCreateEquipment({
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['equipments'] });
      navigate(`/equipments`);
    },
    onError: FormTransformers.errorsTransformer<EquipmentValidationSchema>({ setError })
  });

  const onSubmit: SubmitHandler<EquipmentValidationSchema> = (data) => createEquipment(data);

  const documents = watch('documents') || [];

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex justify-between items-center mb-6">
        <div className="flex gap-2 items-center">
          <Link to='/equipments' className="cursor-pointer">
            <ChevronLeft size={40} strokeWidth={1} />
          </Link>

          <p className="text-white-400 font-normal">
            Equipamentos
          </p>

          <ChevronsRight color='#ffffffb3' />

          <h2>
            Novo equipamento
          </h2>
        </div>

        <div className="flex gap-4">
          <Link to='/equipments'>
            <Button variant='outline-error'>
              Cancelar
            </Button>
          </Link>

          <Button type="submit" className="shadow-[4px_4px_8px_4px_rgba(0,_0,_0,_0.2)]">
            {createEquipmentIsPending ? <Spinner className="h-6 mx-[23px] fill-white-800" /> : 'Confirmar'}
          </Button>
        </div>
      </div>

      <Box className="flex flex-col gap-4 shadow-[4px_4px_8px_4px_rgba(0,_0,_0,_0.2)] overflow-y-auto max-h-[calc(100vh-128px)]">
        <p className="text-lg font-bold">
          Dados do equipamento
        </p>

        <MultipleImageUploader
          label="Anexe uma foto"
          name="equipment-images"
          accept="image/*"
          uploadBlob={uploadBlob}
          control={control}
          fieldName="photos"
          disabled={uploadBlobIsPending || createEquipmentIsPending}
        />

        <SelectController
          control={control}
          name="branch_id"
          label="Filial"
          markAsRequired
          onInputChange={setSearchCompanyBranch}
          options={companyBranchOptions || []}
          errorMessage={errors.branch_id?.message || undefined}
          isDisabled={createEquipmentIsPending}
        />

        <PeriodRestriction
          control={control}
          register={register}
          watch={watch}
          errors={errors}
          disabled={createEquipmentIsPending}
        />

        <EquipmentDetails
          control={control}
          register={register}
          watch={watch}
          setValue={setValue}
          errors={errors}
          disabled={createEquipmentIsPending}
        />

        <Accordion
          head={
            <Checkbox
              label="Documentos do equipamento"
              name="equipment_documents"
              boxClassName="w-[24px] h-[24px]"
              checked={documents.length > 0}
              disabled
            />
          }
          body={(
            <div className="flex w-full flex-col gap-4 text-white-800">
              <MultipleDocumentUploader
                title="Anexe um documento do equipamento"
                fieldName="documents"
                name="equipment-documents"
                accept="application/pdf"
                uploadBlob={uploadBlob}
                control={control}
                disabled={uploadBlobIsPending || createEquipmentIsPending}
              />
            </div>
          )}
        />

        <ApplicableProducts
          control={control}
          register={register}
          watch={watch}
          errors={errors}
          disabled={createEquipmentIsPending}
        />
      </Box>
    </form>
  );
};

export default EquipmentsCreate;
