import Button from '@/common/components/Button';
import { ShowHeader } from '@/common/components/ShowHeader';
import Tabs from '@/common/components/Tabs';
import { Link, useParams } from 'react-router-dom';
import useEquipment from '../../hooks/useEquipment';
import { EquipmentData } from '../../components/show/equipmentData';
import { ConsumptionHistory } from '../../components/show/consumptionHistory';
import EquipmentLogChanges from '../../components/show/changeHistory';
import { ErrorMessageWrapper } from '@/common/components/ErrorMessageWrapper';
import Badge from '@/common/components/Badge';
import React from 'react';

const EquipmentsShow = () => {
  const { id: equipmentId } = useParams();

  const {
    data: equipment,
    isPending: equipmentIsPending,
    error: equipmentError = {} as any,
    refetch: refetchEquipment,
  } = useEquipment({ id: equipmentId || '' });

  const disableUpdateButton = equipmentIsPending || equipmentError?.status === 403;

  return (
    <div>
      <ShowHeader
        backTo='/equipments'
        logoUrl={equipment?.photos?.[0]?.url}
        title={equipment?.license_plate || equipment?.official_name || 'Não informado'}
        badge={
          equipment?.status === 'disabled' ? (
            <Badge
              Icon={React.Fragment}
              text="Desativado"
            />
          ) : equipment?.status === 'blocked' ? (
            <Badge
              Icon={React.Fragment}
              text="Bloqueado"
            />
          ) : <></>
        }
        buttons={(
          <Link
            to={disableUpdateButton ? '' : `/equipments/${equipmentId}/edit`}
            className={disableUpdateButton ? 'cursor-default' : ''}
          >
            <Button
              type="button"
              className="shadow-[4px_4px_8px_4px_rgba(0,_0,_0,_0.2)]"
              disabled={disableUpdateButton}
            >
              Editar
            </Button>
          </Link>
        )}
      />

      <ErrorMessageWrapper
        className="max-h-[calc(100vh-128px)]"
        statusCode={equipmentError?.status}
        refetchFunction={refetchEquipment}
      >
        <Tabs
          tabsListClassName="w-full bg-transparent rounded-none"
          tabsTriggerClassName="h-8 w-full rounded-none border-b border-[#444444] text-white-800 text-xs data-[state=active]:bg-transparent data-[state=active]:text-white-800 data-[state=active]:shadow-none data-[state=active]:border-blue-800"
          tabsContentClassName="mt-6"
          tabsList={[
            {
              label: 'Dados',
              value: '',
              body: <EquipmentData equipmentId={equipmentId} equipment={equipment} />,
            },
            {
              label: 'Histórico de consumo',
              value: 'consumptionHistory',
              body: <ConsumptionHistory />,
            },
            {
              label: 'Histórico de mudanças',
              value: 'changeHistory',
              body: <EquipmentLogChanges equipmentId={equipmentId} />,
            },
          ]}
        />
      </ErrorMessageWrapper>
    </div>
  );
};

export default EquipmentsShow;
