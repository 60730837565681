import { twMerge } from "tailwind-merge";

interface ResumeItemProps {
  label: string;
  value: string;
  className?: string;
  labelClassName?: string;
  valueClassName?: string;
  containerClassName?: string;
}

const ResumeItem = ({ label, value, className, labelClassName, valueClassName }: ResumeItemProps) => {
  return (
    <div className={twMerge('flex flex-col', className)}>
      <p className={twMerge('font-light italic text-[14px] text-white-800', className, labelClassName)}>{label}</p>
      <p className={twMerge('text-[18px] text-white-800', className, valueClassName)}>{value || '--'}</p>
    </div>
  );
}

export default ResumeItem;
