export const governmentAllocationDocumentsTableColumns = [
  { label: 'N do empenho', value: 'office_number' },
  { label: 'Vlr. empenhado', value: 'total_value' },
  { label: 'Tipo', value: 'kind' },
  { label: 'Status', value: 'status' },
  { label: 'Data de upload', value: 'uploaded_at' },
  { label: 'Solicitante', value: 'requester' },
  { label: 'Avaliador', value: 'responsible' },
  { label: 'Ações', value: 'action', className: 'w-9' },
]
