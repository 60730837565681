import usePrivateAxios from '@/common/api/usePrivateAxios'
import { defaultTransformerResponse } from '@/common/constants/defaultTransformerResponse.constant'
import { Mapper } from '@/common/services/mapper'
import { MetaResponse } from '@/common/types/metaResponse'
import { useQuery } from '@tanstack/react-query'

type UseGovernmentAllocationEntriesProps<T> = {
  governmentAllocationId: string
  filters: {
    page?: number
  }
  mapper?: (data: any) => T
}

export const useGovernmentAllocationEntries = <T,>({
  governmentAllocationId,
  filters: { page },
  mapper = Mapper.default<T>(),
}: UseGovernmentAllocationEntriesProps<T>) => {
  const privateAxios = usePrivateAxios()

  const { data: { data, meta } = defaultTransformerResponse(), ...rest } = useQuery({
    queryKey: ['governmentAllocationEntries', governmentAllocationId, page],
    queryFn: () =>
      privateAxios.get(`/billing/government_allocations/${governmentAllocationId}/entries`, {
        params: {
          page,
        },
      }),
    select: (data: { data: { data: any[]; meta: MetaResponse } }) => {
      return {
        data: mapper(data?.data?.data),
        meta: data?.data?.meta,
      }
    },
  })

  return {
    data,
    meta,
    ...rest,
  }
}
