import Button from "@/common/components/Button";
import Modal from "@/common/components/Modal";
import TextField from "@/common/components/TextField";
import { InfoIcon } from "lucide-react";
import { twMerge } from "tailwind-merge";
import useCompanyEquipmentBlock from "../../hooks/useEquipmentBlock";
import useCompanyEquipmentUnblock from "../../hooks/useEquipmentUnblock";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { equipmentBlockSchema, EquipmentBlockValidationSchema } from "../../schemas/blockEquipment";
import { useQueryClient } from "@tanstack/react-query";
import { ModalMessageSimple } from "@/common/components/ModalMessageSimple";
import { FormTransformers } from "@/common/utils/formTransformers";
import { EquipmentData } from "../../types/equipments.type";

const BlockUnblockEquipment = ({ equipment }: { equipment: EquipmentData; }) => {
  const queryClient = useQueryClient();

  const [messages, setMessages] = useState<string[] | boolean>(false);

  const [blockModalIsOpen, setBlockModalIsOpen] = useState(false);
  const [unblockModalIsOpen, setUnblockModalIsOpen] = useState(false);

  const { mutate: blockEquipment } = useCompanyEquipmentBlock({
    equipmentId: equipment.id,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['equipment', equipment.id] });
      formBlockEquipment.reset();
      setBlockModalIsOpen(false);
    },
    onError: (error) => {
      FormTransformers.errorsTransformer({ setError: formBlockEquipment.setError })(error);
      setMessages((error.response?.data as { errors: { base: string[]; }; })?.errors?.base || null);
    },
  });

  const { mutate: unblockEquipment } = useCompanyEquipmentUnblock({
    equipmentId: equipment.id,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['equipment', equipment.id] });
      setUnblockModalIsOpen(false);
    },
    onError: (error) => {
      setMessages((error.response?.data as { errors: { base: string[]; }; })?.errors?.base || null);
    },
  });

  const isBlocked = equipment.status === 'blocked';
  const isDisabled = equipment.status === 'disabled';

  const formBlockEquipment = useForm<EquipmentBlockValidationSchema>({
    resolver: zodResolver(equipmentBlockSchema),
  });

  return (
    <>
      <div className="flex flex-col gap-3">
        {!isDisabled && (
          <div className={twMerge("flex w-full justify-between items-center bg-white-100 rounded-lg p-2", isBlocked && 'bg-[#FCCDCA33]')}>
            <div className="flex items-center gap-x-2">
              <InfoIcon strokeWidth={1} className="text-yellow-600" />

              <div>
                <p className="font-bold text-[10px] leading-3">
                  {!isBlocked ? (
                    'Bloqueio do equipamento impede que todas as transações sejam realizadas.'
                  ) : (
                    'Desbloqueie o equipamento para permitir as transações.'
                  )}
                </p>

                <p className="font-light text-[10px] italic leading-3">
                  {!isBlocked ? (
                    'Caso o equipamento seja bloqueada, nenhuma transação poderá ser feita nele.'
                  ) : (
                    'Se o equipamento for desbloqueada, transações poderão ser feitas.'
                  )}
                </p>
              </div>
            </div>

            {!isBlocked ? (
              <Modal
                Trigger={(
                  <Button type="button" variant="error">
                    Bloquear equipamento
                  </Button>
                )}
                title="Você tem certeza?"
                subtitle="Caso o equipamento seja bloqueada, nenhuma transação poderá ser feita nele."
                subtitleClassName="mt-1 text-white-300"
                showClose={false}
                useCancellableFooter
                isOpen={blockModalIsOpen}
                setIsOpen={setBlockModalIsOpen}
                onConfirm={formBlockEquipment.handleSubmit((data) => blockEquipment(data))}
                body={(
                  <form className="flex flex-col gap-4">
                    <TextField
                      className="text-white-800 opacity-80"
                      label="Motivo"
                      {...formBlockEquipment.register('block_reason')}
                      errorMessage={formBlockEquipment.formState.errors.block_reason?.message || undefined}
                    />
                  </form>
                )}
              />
            ) : (
              <Modal
                Trigger={(
                  <Button type="button">
                    Desbloquear equipamento
                  </Button>
                )}
                title="Você tem certeza?"
                subtitle="Se o equipamento for desbloqueada, transações poderão ser feitas."
                subtitleClassName="mt-2 text-white-300"
                confirmButtonClassName="bg-blue-700"
                showClose={false}
                useCancellableFooter
                isOpen={unblockModalIsOpen}
                setIsOpen={setUnblockModalIsOpen}
                onConfirm={() => unblockEquipment()}
              />
            )}
          </div>
        )}
      </div>

      {messages && (
        <ModalMessageSimple
          messages={messages}
          setMessages={setMessages}
          isError
        />
      )}
    </>
  );
};

export default BlockUnblockEquipment;
