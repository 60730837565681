import TextField from '@/common/components/TextField';
import { DatePicker } from '@/common/components/ui/datePicker';
import { filterLabel } from '@/features/reports/constants/filterLabel.constant';
import { Control, Controller } from 'react-hook-form';

interface FilterDefaultFieldType {
  [key: string]: (props: {
    control: Control;
    filterName: string;
    value: string;
    setFilterParams: (mutationFn: (searchParams: any) => any) => void;
  }) => React.ReactNode;
}

export const defaultReportsFilterFields: FilterDefaultFieldType = {
  textField: ({ control, filterName, value, setFilterParams }) => (
    <Controller
      control={control}
      name={filterName}
      render={({ field: { onChange, ...rest } }) => (
        <TextField
          {...rest}
          label={filterLabel[(filterName as keyof typeof filterLabel) || 'default']}
          className="w-full"
          value={value}
          onChange={(event: any) => {
            onChange(event);
            setFilterParams((searchParams) => ({
              ...searchParams,
              q: { ...searchParams.q, [filterName]: event.target.value },
            }));
          }}
        />
      )}
    />
  ),
  dateField: ({ control, filterName, value, setFilterParams }) => (
    <Controller
      control={control}
      name={filterName}
      render={({ field }) => (
        <DatePicker
          label={filterLabel[(filterName as keyof typeof filterLabel) || 'default']}
          date={!!value ? new Date(value) : undefined}
          setDate={(value) => {
            field.onChange(value);
            setFilterParams((searchParams) => ({ ...searchParams, q: { ...searchParams.q, [filterName]: value } }));
          }}
          variant="default"
          className="w-full"
          inputClassName="h-[52px] text-sm rounded-md rounded-r-none bg-neutral-op-8 hover:bg-neutral-op-8"
        />
      )}
    />
  ),
};
