import { ErrorMessageWrapper } from '@/common/components/ErrorMessageWrapper';
import ListHeader from '@/common/components/ListHeader';
import Table from '@/common/components/Table';
import { useFilterParams } from '@/common/hooks/useFilterParams';
import { Mapper } from '@/common/services/mapper';
import { FileSpreadsheetIcon } from 'lucide-react';
import { permittedReportsTableColumns } from '../../constants/permittedReportsTableColumns.constant';
import { usePermittedReports } from '../../hooks/usePermittedReports';
import { PermittedReportsTransformer } from '../../services/permittedReportsTransformer';

const ReportsPermittedReports = () => {
  const { filterParams, setFilterParams } = useFilterParams();

  const { data: permittedReports, meta } = usePermittedReports({
    filters: {
      page: filterParams.page || 1,
    },
    mapper: Mapper.mapWithTransformer({ transformer: PermittedReportsTransformer.tableTransformer() }),
  });

  return (
    <div className="flex flex-col gap-6">
      <ListHeader Icon={<FileSpreadsheetIcon className="w-10 h-10" strokeWidth={1} />} title="Relatórios" />

      <ErrorMessageWrapper className="max-h-[calc(100vh-128px)]" statusCode={200} refetchFunction={() => null}>
        <div className="h-[calc(100vh-128px)] flex flex-col gap-6">
          <Table
            columns={permittedReportsTableColumns}
            isLoadingData={false}
            page={Number(filterParams.page) || 1}
            rows={permittedReports}
            totalPages={meta?.total_pages || 1}
            totalCount={meta?.total}
            hasPagination
            setPage={(page) => setFilterParams((prev) => ({ ...prev, page }))}
            rowEmptyValue="--"
          />
        </div>
      </ErrorMessageWrapper>
    </div>
  );
};

export default ReportsPermittedReports;
